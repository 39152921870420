import "./MainStyles.css";
import video from "./assets/videoBg.mp4";
function Main (props){
    return(
        <>
        <div className={props.cName}>
            <div className="overlay-video" ></div>
           <video src={props.mainvideo  } autoPlay loop muted/>
           <div className="content">
            <h1>{props.title}</h1>
            <h2>{props.title2}</h2>
            <p> {props.text}</p>
            <p> {props.text1}</p> 
            <p> {props.text2}</p>
            <h3>{props.title3}</h3>
            </div>
        </div>
        </>


    );
}
export default Main;