import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
// import * as employeeService from "../../employeeService";
import Controls from "../components/partenaire/PartMenu/controls/Controls";
import { useForm, Form } from "./useForm";
import ReactImageUploading from "react-images-uploading";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { useAuth } from "../AuthContext";
import DatePicker from "../components/partenaire/PartMenu/controls/DatePicker";
import { toast } from "react-toastify";

const initialFValues = {
  id: 0,
  fullName: "",
  email: "",
  mobile: "",
  city: "",
  gender: "male",
  departmentId: "",
  hireDate: new Date(),
  isPermanent: false,
};

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export default function EmployeeForm({ close, ...props }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [dateDebut, setDateDebut] = useState(Date());
  const [dateFin, setDateFin] = useState(Date());

  const [file, setFile] = useState(null);
  console.log(file);
  const { token, isSignedIn } = useAuth();
  console.log(token);
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 8 numbers required.";
    if ("departmentId" in fieldValues)
      temp.departmentId =
        fieldValues.departmentId.length != 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  console.log("----------------->  ");
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
    const formData = new FormData(e.target);

    formData.append("dateDebut", formatDate(dateDebut));
    formData.append("dateFin", new Date(dateFin).toISOString().slice(0, 10));
    formData.append("photo", file);
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    
    const id = toast.loading("Loading...");

    axios
      .post("http://localhost:8000/api/partenaire/Offer/store", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "image/jpeg",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast.update(id, {
          render: res.data,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        close();
      })
      .catch((err) =>
        toast.update(id, {
          render: err?.response?.data?.message || "erreur",
          type: "error",
          autoClose: 1000,
          isLoading: false,
        })
      );
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={6}>
            <Controls.Input name="nom" label="Nom" error={errors.fullName} />
            <Controls.Input
              name="description"
              label="Description"
              error={errors.description}
            />
            <Controls.Input label="Adresse" name="adress" />
            <Controls.Input
              label="Réduction"
              name="reduction"
              type="number"
              error={errors.reduction}
            />
            <Controls.Input
              type="number"
              label="Mobile"
              name="tel"
              error={errors.mobile}
            />

            <Controls.Input
              label="Prix"
              name="prix"
              type="number"
              error={errors.prix}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="Date début"
              name="dateDebut"
              onChange={(e) => setDateDebut(e.target.value)}
              value={dateDebut}
            />
            <DatePicker
              label="Date fin"
              name="dateFin"
              onChange={(e) => setDateFin(e.target.value)}
              value={dateFin}
            />
            <div>
              <Button htmlFor="image" component="label">
                Choisir photo
              </Button>
              <input
                onChange={(e) => setFile(e.target.files[0])}
                id="image"
                name="image"
                type="file"
                style={{ visibility: "hidden" }}
              />
              {file && <img height="100px" src={URL.createObjectURL(file)} />}
            </div>

            <div>
              <Controls.Button text="Sauvegarder" type="submit" />
              <Controls.Button
                text="Reset"
                color="default"
                onClick={resetForm}
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
