import { Component } from "react";
import "./NavbarStyles.css";
import img from "./assets/yooreed.png";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom";
class Navbar extends Component{
    state = { clicked: false};
    handleClick = () =>{
        this.setState({ clicked: !this.state.clicked})
    };
    render(){
        return(
            <nav className="NavbarItems">
                    <nav className="navbar-logo">
                <img src="assets/yooreed.png"  width={69.93} height={44.3} />
             
                </nav>

                <div className="menu-icoss" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" :"fas fa-bars"}></i>
                   
                </div>

              <ul className={this.state.clicked? "nav-menu active" : "nav-menu"}>
                 {MenuItems.map((item, index) =>{
                    return (
                        <li key={index}>
                    <Link className={item.cName}to={item.url}>
                        <i className={item.icon}></i>{item.title}
                    </Link>
                </li>
                    );
                 }
                 )}
              <Link className="nav-linkers" to="/login">
               <i><button>Connexion</button></i></Link>
            </ul>
         </nav>
        );
    }
}
export default Navbar;
