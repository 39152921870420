import Img4 from "./assets/img4.jpg"

import"./YooreedService.css"
import{FiVolume1}from 'react-icons/fi';
import{FaRegCreditCard}from 'react-icons/fa';
import{FaRegHandshake}from 'react-icons/fa';
import{FaChalkboardTeacher}from 'react-icons/fa';

const YooreedService = ()=> {
    return (
        <div className="yooreedService">
            
                       <div className="overlay-img" >   </div> 
                      <div className="image2" >
                        
                         <img alt="img" src= {Img4} /> 

                         <div className="card-container1">
                         <div className="Service-icons1">
                         
                                 <FiVolume1 size="7rem"/>
                               
                                </div>
                                <div className="card-text1">
                                  <h3> Service Yooreed</h3>
                                  </div>
                                  <div className="card-textt1">
                                  <p> Gérer les utililisateurs de la</p>
                                  </div>
                                  <div className="card-texttt1">
                                  <p> plateforme(clients et </p>
                                  </div>
                         </div>

                         <div className="card-container2">
                         <div className="Service-icons2">
                                 
                                 <h2><FaRegCreditCard size="7rem"/></h2> </div>
                     
                         
                         <div className="card-text2">
                                  <h3> Pour les clients</h3>
                               </div>
                               <div className="card-textt2">
                                  <p> S'inscrire sur la plateforme</p>
                                  </div>
                                  <div className="card-texttt2">
                                  <p> en introduisant l'identifiant </p>
                                  </div>
                               </div>
                         <div className="card-container3">
                         <div className="Service-icons3">
                         
                         <h2><FaRegHandshake size="7rem"/></h2></div>
                         <div className="card-text3">
                                  <h3> Pour les entreprises</h3>
                               </div>
                               <div className="card-textt3">
                                  <p> Gérer les offres(consulter</p>
                                  </div>
                                  <div className="card-texttt3">
                                  <p> les demandes ,proposer</p>
                                  </div>
                         </div>
                         
                         <div className="card-container4">
                         <div className="Service-icons4">
                         
                         <h2><FaChalkboardTeacher size="7rem"/></h2> </div>
                         <div className="card-text4">
                                  <h3> Pour les utililisateurs ordinaires</h3>
                               </div>
                               <div className="card-textt4">
                                  <p> Disponibilité de site pour </p>
                                  </div>
                                  <div className="card-texttt4">
                                  <p> tout utilisateur </p>
                                  </div>
                         </div>
                        
                         
            
            
                                  
            </div>
            
            </div>
            
    );
};
export default YooreedService;

