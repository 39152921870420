import Img5 from "./assets/aaa.png";
import Img6 from "./assets/aa.png";
import axios from "axios";
import { useState, useEffect } from "react";
import "./YooreedPartenaire.css";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
function YooreedPartenaire() {
  const [sponsors, setSponsors] = useState([]);
  useEffect(() => {
    
    axios
      .get(`http://localhost:8000/api/Guest/Sponsor`)
      .then((response) => {
        setSponsors(response.data);
      })
      .catch((error) => {
        console.error("Error fetching sponsors:", error);
        setSponsors([]);
      });
  }, []);

  return (
    <div className="yooreedPartenaire">
      <div className="card-partenaire">
        <div className="image-partenaire">
          <img alt="img" src={Img5} />
          {/* <div className="image-partenaire2">
            <img alt="img" src={Img6} />
          </div> */}

<h1>04</h1>
          <h3> Nos Sponsors </h3>
          <div className="service3">
            <div className="service-text2">
              <h2>Tous les sponsors </h2>
            </div>
            <div className="cards-container">
              {sponsors.map((sponsor) => (
              <div className="card" key={sponsor.id}>
              <a
                href={`${sponsor.link}`}
                target="_blank"
                rel="noopener noreferrer"
                className="card-link"
              >
                <img
                  id="card-image-fixed"
                  alt="img"
                  src={`http://localhost:8000/uploads/${sponsor.image}`}
                  className="card-image"
                />
              </a>
            </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default YooreedPartenaire;