import React, { useState } from 'react';
import "./AdherentCom.css";
import PageContent from "./pageContenet";
import SideMenu from "./SideMenu/filtrePerman";
import Publicite from "./publicite";
import TestOffre from "./testOffre";
import Search from './Searchh/ntasti';

function AdherentCom({ results }) {
  const [filteredResults, setFilteredResults] = useState([]);

  const handleFilterChange = (filteredResults) => {
    setFilteredResults(filteredResults);
  };

  const allResults = [...results, ...filteredResults];

  return (
    <div className="Appp">
      <div className="SideMenuAndPageContent">
        <SideMenu setResults={handleFilterChange} />
        {/* <Search setResults={setResults} /> */}
        <PageContent results={allResults} />
        <Publicite />
      </div>
    </div>
  )
}

export default AdherentCom;
