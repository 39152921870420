export const MenuItems =[
    {
        title :"Offres Permanents",
        url:"/adherent",
        cName:"nav-links",
        icon:"fa-solid fa-house"
    },
    {
        title :"News",
        url:"/news",
        cName:"nav-links",
        icon:"fa-solid fa-briefcase"
    },

    {
        title :"Offres Yooreed",
        url:"/offresyooreed",
        cName:"nav-links",
        icon:"fa-solid fa-users"
    },




 

]