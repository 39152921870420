import NavB from "../components/adherent/NavB"
import AdherentCom from "../components/adherent/AdherentCom"
import { useState } from "react";
import { useAuth } from "../AuthContext";
function Adherent (){
    const [results, setResults] = useState([]);
    const { isSignedIn } = useAuth();
    if (!isSignedIn) return <h1>Not Logged In</h1>;
    return(
        <>
            <NavB setResults={setResults}/>
            <AdherentCom results={results} />
        </>


    );
}
export default Adherent;