import { useState } from "react";
// import { FaSearch } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import "./ntasti.css";

const Ntasti = ({ setResults }) => {
  const [input, setInput] = useState("");

  const fetchData = (value) => {
    fetch("http://localhost:8000/api/adherent/Permanent/Offer/")
      .then((response) => response.json())
      .then((json) => {
        const results = json.filter((user) => {
          return (
            value &&
            user &&
            user.partenaires.nom &&
            user.partenaires.nom.toLowerCase().includes(value)
          );
        });
        console.log(results);
        setResults(results);
      });
  };

  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  return (
    <div className="input-wrapper">
      <FontAwesomeIcon icon={faMagnifyingGlass} width={15} id="search-icon"  />
      <input
        placeholder="Rechercher..."
        value={input}
        onChange={(e) => handleChange(e.target.value)}
        className='bar_de_recherche'
      />
    </div>
  );
};

export default Ntasti;