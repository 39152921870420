import  { useState } from "react";
import "./ServiConten.css";
import Img4 from "./assets/4.png";
import Img44 from "./assets/55.png";
import Img6 from "./assets/6.png";
import Img7 from "./assets/7.png";
import Img5 from "./assets/5.png";
import Img10 from "./assets/10.png";
import Img12 from "./assets/12.png";





function Service (){
    const serviices = ["Service Yooreed", "Pour les entreprises", "Pour les internautes", "Pour les clients"];
    const [myService, setMyService] = useState("");
    return(

        <>
         {/* <img src={Img6}  className="image6"  /> */}
         
        
        <img src={Img4}  className="image1"  />
        <img src={Img10}  className="image10"  />
        
<div className="jalel">
        <tr className="jalel">
       
        <td>
        
        <div
                className="btngroupp"
                role="group"
                aria-label="Basic example"
            >
                {serviices.map(service => (
                    <button
                        type="button"
                        key={service}
                        className={"btn btn-light border-dark "}
                        onClick={() => setMyService(service)}    
                    >
                        {service.toLocaleUpperCase()  } 

                    </button>
                    
                ))}
            </div>
            </td>
            <td>
              <br/>   <br/>   <br/>   <br/>   <br/>   <br/>  
            <div className=" text-center">
            {myService === "" && (
        <img src={Img44} className="image11m" ></img>
            )}
            <div className="prag">

            
                {myService === "Service Yooreed" && (
                  <p>
                  -Gérer les utilisateurs de la plateforme (clients et

fournisseur).<br/>

-Gérer les contrats commerciaux entre les clients des groupements, semi-professionnels et les fournisseurs.<br/>

-Gérer les offres commerciales.<br/>

-Suivre modalité de livraison et paiement. </p>
                )}
                {myService === "Pour les entreprises" && (
                    <p>
                    -Gérer les offres (consulter les demandes, proposer,

négocier, réviser les propositions).<br/> -Valider le code de bon de commande fourni par le client.<br/>

-Consulter les contrats.<br/>

-Consulter l'historique d'achat.<br/>
                 </p>
                )}
                {myService === "Pour les internautes" && (
                    <p>
                    -Disponibilité de site pour tout utilisateur.<br/>
 
 -Générer un bon de commande selon la modalité de paiement choisi.<br/>
 
 -Une gestion de temps et rapidité de réponse .<br/>-Utilisation simple et facile.<br/>
                   </p>
                )}
                {myService === "Pour les clients" && (
                   <p>
                   -S'inscrire sur la plateforme en introduisant l'identifiant

unique fourni par l'amicale, l'association ou autres corps de métier.<br/>

-Consulter l'historique de son achat.<br/>

-Générer un bon de commande selon la modalité de

paiement choisi.<br/> 
                 </p>
                )}
            
            </div>        
        </div>
            </td>
            </tr>
            </div>
           
           {/* <img src={Img7}  className="image7"  /> */}
           <img src={Img5}  className="image555"  />
           <img src={Img12}  className="image12"  />

        </>
    )
    

}

export default Service;
