import NavB from "../components/adherent/NavB"
import OffYooreed from "../components/adherent/offYooreed"
import { useAuth } from "../AuthContext";
function OffresYooreed (){
    const { isSignedIn } = useAuth();
    if (!isSignedIn) return <h1>Not Logged In</h1>;
    return(
        <>
            <NavB/>
            <OffYooreed/>
        </>


    );
}
export default OffresYooreed;