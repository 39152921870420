import { Component } from "react";
import React, { useState } from "react";
import "./NavPart.css";


import { NavItems } from "./NavItems";
import { Link } from "react-router-dom";
import { Badge, Drawer, Image, List, Space, Typography } from "antd";
import ModelL from "./Modal";
import Profile from './profile';
import AOS from 'aos';
import 'aos/dist/aos.css';
class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
    const [modal, setModal] = useState(false);
  };


  render() {
    return (
      <nav className="NavItems">
        <nav className="navbar-logo">
          <img src="/assets/yooreed.png" width={69.93} height={44.3} />
        </nav>

        <div className="menu-icons" onClick={this.handleClick}>
          <i
            className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>

        <ul
          className={
            this.state.clicked ? "nav-menu-part active" : "nav-menu-part"
          }
        >
          {NavItems.map((item, index) => {
            return (
              <li key={index}>
                <Link className={item.cName} to={item.url}>
                  <i className={item.icon}></i>
                  {item.title}
                </Link>
              </li>
            );
          })}

          <Space>
            <ModelL />
          </Space>
          <Space >
                   <Profile />
          </Space>
        </ul>
      </nav>
    );
  }
}
export default Navbar;
