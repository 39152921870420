import axios from "axios"

export const api = axios.create({
    baseURL: 'https://127.0.0.1:8000/api'
})

export const getPartenaires = async () => {
  const response = await api.get('/offer/search/permanent')
  return response.data
}
