import NavPart from "./NavPart";
import "./PartAcceuil.css";
import React, { useEffect, useState } from "react";


function PartAcceuil() {

  
  return (
    <>
   
   
    
    </>
  );
}

export default PartAcceuil;
