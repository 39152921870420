import React, { Component } from "react";
import "./NavBar.css";
import { MenuItems } from "./ItemsMenu";
import { Link } from "react-router-dom";
import { Space } from "antd";
import Notification from "./Notification";
import Search from './rechercherNew/RechercherNew';
import Profil from './profil'

class NavNew extends Component {
    state = { clicked: false };

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked });
    };

    render() {
        return (
            <nav className="NavbarItem">
                <img src="assets/yooreed.png" width={100} height={50} />
                <div className="menu-icons" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>
                <ul className={this.state.clicked ? "nav-menu-adher active" : "nav-menu-adher"}>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index} className="vvvvvv">
                                <Link className={item.cName} to={item.url}>
                                    <i className={item.icon}></i>{item.title}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
                <Space>
                    <Search setResults={this.props.setResults} />
                </Space>
                <Space>
                    <Notification />
                </Space>
                <Space>
                    <Profil />
                </Space>
            </nav>
        );
    }
}

export default NavNew;
