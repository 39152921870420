import React from 'react'
import"./PartAcce.css";
import { Card } from "../components/Card";
import conimg from "../components/assets/yooreed.jpg";
import ImageContact from "../components/ImageContact";
import { faHeart, faMicrochip } from "@fortawesome/free-solid-svg-icons";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import { faBurger } from "@fortawesome/free-solid-svg-icons";
import { faCarBurst } from "@fortawesome/free-solid-svg-icons";
import { faTrainSubway } from "@fortawesome/free-solid-svg-icons";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
function PartAcce() {
  return (
    <div> { <ImageContact               
        cName="main1"
        image={conimg} 
        overlay-img
        title="Les Partenaires"/>}</div>

      
  )
}

export default PartAcce