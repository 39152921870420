import React from 'react';
import "./AdherentCom.css";
import PageContPub from "./pageContPub";
// import SideMenu from "./SideMenu";
import Publicite from "./publiciteYooreed";

function Pub({ results }) {
  return (
    <div className="Appp">
        
        <div className="SideMenuAndPageContent">
          {/* <SideMenu></SideMenu> */}
          <PageContPub results={results}></PageContPub>
          <Publicite></Publicite>
        </div>
    </div>
  )

}

export default Pub
