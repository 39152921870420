import React, { useState } from "react";




function Scanne (){
    
  

    return(
        <>

      
        </>


    );
}
export default Scanne;