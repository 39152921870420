export const MenuItems =[
    {
        title :"Accueil",
        url:"/",
        cName:"nav-links",
        icon:"fa-solid fa-house"
    },
    {
        title :"Services",
        url:"/Services",
        cName:"nav-links",
        icon:"fa-solid fa-briefcase"
    },

    {
        title :"Partenaires",
        url:"/Partenaires",
        cName:"nav-links",
        icon:"fa-solid fa-users"
    },

  

    {
        title :"A propos",
        url:"/A_propos",
        cName:"nav-links",
        icon:"fa-solid fa-circle-info"
    },

    {
        title :"Contact",
        url:"/Contact",
        cName:"nav-links",
        icon:"fa-solid fa-address-book"
    },



 

]