import Navbar from "../components/Navbar"
import Footr from "../components/Footr"
import Footer from "../components/Footer"
import ImageContact from "../components/ImageContact"
import conimg from "../components/assets/imgContNous.jpg";
import Formulaire from "../components/Formulaire";
import FormContact from "../components/FormContact";
function Contact (){
    return(
        <>
           <Navbar/>
           <ImageContact               
               cName="main1"
               image={conimg} 
               overlay-img

               title="Contactez-Nous"
            
              
             />
                <Formulaire/>
              <Footr/>
                <Footer/>
        </>

    )
}
export default Contact;