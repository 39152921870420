import React from 'react'
import { Card } from "../components/Card";
import "./Secteur.css"
import { faHeart, faMicrochip } from "@fortawesome/free-solid-svg-icons";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import { faBurger } from "@fortawesome/free-solid-svg-icons";
import { faCarBurst } from "@fortawesome/free-solid-svg-icons";
import { faTrainSubway } from "@fortawesome/free-solid-svg-icons";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
function Secteur() {
  return (
    <div className="service">
        <div className="three_grid">
          <Card icon={faHeart} title="SANTE" />
          <Card icon={faBookOpen} title="EDUCATION" />
          <Card icon={faPlane} title="TOURISME" />
          <Card icon={faBurger} title="ALIMENTATION" />
          <Card icon={faCarBurst} title="ASSURENCE" />
          <Card icon={faTrainSubway} title="TRANSPORT" />
        </div>
        <div className="two_grid">
          <Card icon={faGamepad} title="LOISIR" className="item-a"/>
          <Card icon={faMicrochip} title="TECHNOLOGIE" />
        </div>
      </div>
      
  
  )
}

export default Secteur