import React, { useState } from 'react';
import "./AdherentCom.css";
import PageContNew from "./pageConNew";
import SideMenu from "./SideMenu/filtre";
import Publicite from "./publicite";

function New({ results }) {

  const [filteredResults, setFilteredResults] = useState([]);

  const handleFilterChange = (filteredResults) => {
    setFilteredResults(filteredResults);
  };

  const allResults = [...results, ...filteredResults];
  return (
    <div className="Appp">
        
        <div className="SideMenuAndPageContent">
          <SideMenu setResults={handleFilterChange} />
          <PageContNew results={allResults}></PageContNew>         
            <Publicite></Publicite>
          
        </div>
    </div>
  )

}

export default New
