import Navbar from "../components/Navbar"
import PartAcce from "../components/PartAcce";
import Secteur from "../components/Secteur";
import Footer from "../components/Footer"
import Footr from "../components/Footr"
function Partenaires (){
    return(
        <>
                <Navbar/>
                <PartAcce/>
                <Secteur/>
                <Footr/>
                <Footer/>
             
        </>

    )
}
export default Partenaires;