import Navbar from "../components/Navbar"
import Main from "../components/Main";
import Yooreed from "../components/Yooreed";
import YooreedData from "../components/YooreedData";
import YooreedData2 from "../components/YooreedData2";
import YooreedService from "../components/YooreedService";
import YooreedChart from "../components/YooreedChart";
import YooreedPartenaire from "../components/YooreedPartenaire";
import YooreedContact from "../components/YooreedContact";
import Contact from "../components/Contact";
import Footr from "../components/Footr"
import Footer from "../components/Footer";

function Accueil (){
    return(
        <>
        <Navbar/>
        <Main 
        cName="main"
        mainvideo="./assets/videoBg.mp4" 
        
        title="Yooreed"
        title2="  ❝  "
        text="  Chez Yooreed, vous trouvez les meilleurs "
        text1="  solution que vous permettent de suivre"
        text2=" l'avanvement de projet et consulter des offres ."
        title3= "  ❞  "
        
        
       
        />
        <Yooreed/> 
        <YooreedData/>
        <YooreedData2/>
        <YooreedService/>
        {/* <YooreedChart/> */}
        <YooreedPartenaire/>
        <YooreedContact/>
        <Contact/>
        <Footr/>
        <Footer/>
        </>


    );
}
export default Accueil;