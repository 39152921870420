import "./footr.css";
import {
    Facebook,
    Instagram,
    Pinterest,
} from "@material-ui/icons";
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Newsletter from "./Newsletter"

function Footr (){

    const [startAnimation,setStartAnimation] = useState(false); 
    const savoirPlus = ["Avantages De La Carte Yooreed", "L'objectif De La Sociétè", "Fonctionnement", "Approche", "Vision"];
    const [myService, setMyService] = useState(false);

    const handleClickOpen=()=>{
        setMyService(!myService)
    }

    const closePopup=()=>{
        setMyService(false)
    }

    const [styling, setstyling] = useState(null);

    useEffect(() => {
        AOS.init({
          duration:2000
        });
    },[]);

    return(
        <>
        <div className="container" > 
            <div className="left" data-aos="fade-left"> 
            < img src="assets/yooreed.png"  width={69.93} height={44.3}  />               
                < p className="desc"> 
                    Yooreed vous trouvé les meilleures solutions. à travers, concevoir une plteforme qui permet aux clients 
                    de suivre l'avancement de projet et consulter des offres proposées par les partenaires.              
                </p>
               
                <div className="SocialContainer" >
                    <tr>
                        <td>< div className="SocialIcon" style={{color:"#3B5999"}}>
                        <Facebook />
                        </div></td>
                        
                        <td> <div className="SocialIcon"  style={{color:"#E4405F"}}>
                        <Instagram />
                        </div> </td>
                        
                        <td> <div className="SocialIcon"  style={{color:"#E60023"}}>
                        <Pinterest />
                        </div></td>
                        
                    </tr>
                </div>
            
            </div>

           

            <div className="Center" data-aos="fade-left">
                
                    <h3 className="Title">En savoir plus</h3>
                    <div className="List">
                        {savoirPlus.map(service => (
                            
                            <div className="ListItem" > 
                            < a style={{color:"black", cursor: "pointer" ,  textTransform :" lowercase" }}  key={service} onClick ={() =>setMyService (service)}  >                       
                                {service.toLocaleUpperCase()}
                            </a>
                            </div>   
                        ))}
                    </div>         
            </div >

        <div className="Right" data-aos="fade-up">
          <h3 className="Title">Newsletter</h3>
          
          <Newsletter/>

        </div>
        
        <div>
            {
            myService?
        <div className="main22" onClick={closePopup}>
        <div className="popup" onClick={(e) => e.stopPropagation()} >
                
                <div className="popup-header" onClick={(e) => e.stopPropagation()}>
                    <p style={{cursor: "pointer" , fontSize: "30px", padding: "35px"  }} onClick={closePopup}>X</p>
                </div>

                <div >
                   
    
                {myService === "Avantages De La Carte Yooreed" && (
                   
                    <p className="ccouleur" style={{position : "relative"}} >
                        <h3 style={{ color: "black",textAlign:"center" ,paddingBottom:"20px" }}>AVANTAGES DE LA CARTE YOOREED</h3>
                        Avantages de carte YOOREED pour les consommateurs:
                        <br/>La carte numérique YOOREED permet à chaque client individuel et ses enfants mineurs de 18 ans de bénéficier des avantages suivants:
                        <br/>* Accés aux divers services de YOOREED 24/24h at 7/7j.
                        <br/>* Réduction importante sur chaque service ou achat des entreprises contractantes avec YOOREED.                      
                  </p>
                )}
                {myService === "Vision" && (
                    <p className="ccouleur">
                        <h3 style={{ color: "black",textAlign:"center"  }}>VISION</h3>
                        Développer avec une stratégie E-Commerce évolutive pour faciliter l'implantation sur leurs Algerian at Maghietin,
                         et toucher E-Commerce dans son approche socio-professionnelle un profit des Coyens, des adhérents et des entreprises économiques
                    </p>
                )}
                {myService === "Approche" && (
                    <p className="ccouleur">
                        <h3 style={{ color: "black",textAlign:"center"  }}>APPROCHE</h3>
                        Il s'agit d'une approche professionnelle développée et anticipative, basée sur l'outil Digital et Technologique, dont notamment:
                        - E-commerce<br/>
                        - Marketing de proximité <br/>
                        - Communication responsable, et respect des données personnelles et professionnelles<br/>
                        - Contrôle, transparence et respect de retiquer commerciale<br/>
                        - Facture électronique<br/>
                        - Contrat électronique à travers des signatures électroniques (identifiant...).
                  </p>
                )}

                {myService === "Fonctionnement" && (
                    <p className="ccouleur">
                        <h3 style={{ color: "black",textAlign:"center" ,paddingBottom:"20px" }}>FONCTIONNEMENT</h3>
                    Yooreed vous trouvez les meilleures solutions. à travers, concevoir une plteforme qui permet 
                    aux clients de suivre l'avancement de projet et consulter des offres proposées par les partenaires.
                  </p>
                )}

                {myService === "L'objectif De La Sociétè" && (
                    <p className="ccouleur">
                        <h3 style={{ color: "black",textAlign:"center" ,paddingBottom:"20px" }}>L'OBJECTIF DE LA SOCIETE</h3>
                    Yooreed a pour objet les activités commerciales et marketing en ligne entre les sociétés, les adhérents des groupements sociaux,
                    et les consommateurs (B to B, B to C), au niveau National et Maghrébin.
                  </p>
                )}
                
                </div>
                </div>  
            </div>  :""
                }  
                 
        </div>

         
        </div>

       
        </>


    );
}
export default Footr;
