import Img1 from "./assets/img1.png"

import"./Yooreed.css"
const Yooreed = ()=> {
    return (
        <div className="yooreed">
          <h1>01</h1>
          <h3> QUI SOMMES NOUS </h3>
          <div className="presentation">
            <div className="des-text">
               <h2>Présentation Yooreed</h2> 
               <p>Yooreed est une structure innovante en commerce </p>
               <p> électronique et développement informatique
               </p>
               <p>Notre objectif : vous satisfaire .</p>
               <div className="image">
              <img alt="img" src= {Img1}/>
              
            </div>
            
          </div>
       </div>
       
     </div>
     
    );
};
export default Yooreed;

