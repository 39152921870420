import React, { useEffect, useState } from "react";
import "./proff.css";
import styled from "styled-components";
import Img44 from "./assets/8.png";
import Img55 from "./assets/9.png";
import openEye from "./open-eye.svg";
import closedEye from "./closed-eye.svg";
import axios from "axios";
import Controls from "../../partenaire/PartMenu/controls/Controls";
import { Button, ButtonGroup } from "@material-ui/core";
import { toast } from "react-toastify";
import { useAuth } from "../../../AuthContext";


const DropDownContainer = styled("div")`
  width: 10.5em;
  margin: 0 auto;
`;

const DropDownHeader = styled("div")`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  //   box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  cursor: pointer;
  width: 30px;
  //   color: #3faffa;
  background: #ffffff;
  border: none;
  border: 0 white;
`;

const DropDownListContainer = styled("div")`
  // padding-left: -20px;
  height: 50px;
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  margin-left: -200px;
  background: #ffffff;
  border: 0px solid #e5e5e5;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  &:first-child {
    padding-top: 0.1em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: -1px;
  display: flex;
`;

export default function éStuProfile() {
  const { userRole, token, logOff } = useAuth();
  const [PasswordVisible, setPasswordVisible] = useState(false);
  const [PasswordIsVisible, setPasswordIsVisible] = useState(false);
  const [PasswordIsVisibleConf, setPasswordIsVisibleConf] = useState(false);
  const [resultat, setResultat] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const [userss, setUserss] = useState([]);
 
  useEffect(() => {
    axios
      .get(`http://localhost:8000/api/Adherent`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // console.log(res.data.amicale)
        // console.log(res.data.gouvernorats)
        setUserss(res.data);
      
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const changerMdp = async () => {
    try {
      const response = await axios.post(
        "http://localhost:8000/api/adherent/changePassword",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setResultat(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    for (const pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
    const toastId = toast.loading("Loading...");

    try {
      const { data } = await axios.post(
        `http://localhost:8000/api/${userRole}/changePassword`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (data.startsWith("l'ancien")) {
        return toast.update(toastId, {
          render: data,
          autoClose: 4000,
          isLoading: false,
          type: "error",
        });
      } else {
        toast.update(toastId, {
          render: data,
          autoClose: 4000,
          isLoading: false,
          type: "success",
        });
      }
      logOff();
    } catch (error) {
      console.log(error);
      toast.update(toastId, {
        render: error?.response?.data?.message || "Erreur",
        autoClose: 4000,
        isLoading: false,
        type: "error",
      });
    }
  };

  return (
    <>
      <div className="globale">
        <img src={Img44} className="image2023" />

        <div className="flex-contrainer">
          <div className="reddd">
            <div className="w-25 border m-5 p-3">
              <div className="imageee">
                <img
                  src="https://www.nicepng.com/png/detail/933-9332131_profile-picture-default-png.png"
                  alt=""
                  className="roundrd-full w-10 h-10"
                  srcSet=""
                />
              </div>
              <div className="Nomp">{<p> {userss.nom} </p>}</div>
              <div className="part">
                <div className="part1">
                  <div className="adr">
                    <h4>Adresse:</h4>
                    <p>{userss.adress}</p>
                  </div>
                  <div className="teleph">
                    <h4>Tel:</h4>
                    <p>{userss.tel}</p>
                  </div>
                  <div className="gouv">
                    <h4>Gouvernorat:</h4>
                    <p>{userss.gov}</p>
                  </div>

                  <div className="vill">
                    <h4>Ville:</h4>
                    <p>{userss.ville}</p>
                  </div>

                  <div className="gouv">
                    <h4>Amical:</h4>
                    <p>{userss.amicale}</p>
                  </div>
                </div>
                <div className="part2">
                  <div className="gouv"></div>
                  <div className="gouv">
                    <h4>Changer le mot de passe:</h4>

                    <DropDownContainer>
                      <DropDownHeader onClick={toggling}>
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/4663/4663114.png"
                          alt=""
                          className="imgg"
                          srcSet=""
                        />
                      </DropDownHeader>
                      {isOpen && (
                        <form id="cp" onSubmit={handleSubmit}>
                          <DropDownListContainer>
                            <DropDownList className="bout">
                              <ListItem>
                                <input
                                  type={PasswordVisible ? "text" : "password"}
                                  className="formcontrolProfil"
                                  placeholder="Password"
                                  name="old_password"
                                />
                                <div
                                  onClick={() =>
                                    setPasswordVisible(
                                      (prevState) => !prevState
                                    )
                                  }
                                  className="frmm"
                                >
                                  <img
                                    src={PasswordVisible ? closedEye : openEye}
                                    alt={
                                      PasswordVisible
                                        ? "Closed Eye"
                                        : "Open Eye"
                                    }
                                  />
                                </div>
                              </ListItem>

                              <ListItem>
                                <div className="eyeAndInput">
                                  <input
                                    name="password"
                                    type={
                                      PasswordIsVisible ? "text" : "password"
                                    }
                                    placeholder="Nouveau"
                                    className="formcontrolProfil"
                                    n
                                  />
                                  <div
                                    onClick={() =>
                                      setPasswordIsVisible(
                                        (prevState) => !prevState
                                      )
                                    }
                                    className="frmm"
                                  >
                                    <img
                                      src={
                                        PasswordIsVisible ? closedEye : openEye
                                      }
                                      alt={
                                        PasswordIsVisible
                                          ? "Closed Eye"
                                          : "Open Eye"
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="eyeAndInput">
                                  <input
                                    name="cpassword"
                                    type={
                                      PasswordIsVisibleConf
                                        ? "text"
                                        : "password"
                                    }
                                    placeholder="Confirmation "
                                    className="formcontrolProfil"
                                  />
                                  <div
                                    onClick={() =>
                                      setPasswordIsVisibleConf(
                                        (prevState) => !prevState
                                      )
                                    }
                                    className="frmm"
                                  >
                                    <img
                                      src={
                                        PasswordIsVisibleConf
                                          ? closedEye
                                          : openEye
                                      }
                                      alt={
                                        PasswordIsVisibleConf
                                          ? "Closed Eye"
                                          : "Open Eye"
                                      }
                                    />
                                  </div>
                                </div>
                              </ListItem>
                              <Button
                                form="cp"
                                type="submit"
                                variant="filles"
                                style={{
                                  background: "#E91E63",
                                  color: "white",
                                }}
                              >
                                Enregistrer
                              </Button>
                            </DropDownList>
                          </DropDownListContainer>
                        </form>
                      )}
                    </DropDownContainer>
                  </div>
                </div>
              </div>
            </div>
            <img src={Img55} className="image2024" />
          </div>
        </div>
      </div>
    </>
  );
}