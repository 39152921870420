import "./Footer.css";


  function Footer (){
    
    return(
        <>
        <div className="containFooter">
            <p>
                Yooreed 2023 All right reserved 
            </p>
        </div>
       
        </>


    );
}
export default Footer;
