import { Grid } from "@material-ui/core";
import Controls from "../components/partenaire/PartMenu/controls/Controls";
import Button from "@mui/material/Button";
import axios from "axios";
import { useAuth } from "../AuthContext";
import DatePicker from "../components/partenaire/PartMenu/controls/DatePicker";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export default function EditOfferForm({ initialValues, close }) {
  console.log(initialValues);

  const { token } = useAuth();
  const [file, setFile] = useState(null);
  const [dateDebut, setDateDebut] = useState(new Date(initialValues.dateDebut));
  const [dateFin, setDateFin] = useState(new Date(initialValues.dateFin));
  useEffect(() => {
    (async () => {
      if (initialValues.image) {
        let response = await axios({
          url: "http://localhost:8000/uploads/" + initialValues.image,
          responseType: "blob",
        });
        console.log(response);

        // let file = new File([data], "test.jpg", metadata);
        // console.log("from async");
        // setFile(file);
      }
    })();
  }, [initialValues]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (validate()) {
    //   // addOrEdit(values, resetForm);
    // }
    const formData = new FormData(e.target);

    formData.append("dateDebut", formatDate(dateDebut));
    formData.append("dateFin", new Date(dateFin).toISOString().slice(0, 10));
    formData.append("photo", file);

    for (const [key, value] of formData.entries()) console.log(key, value);

    const id = toast.loading("Loading...");

    axios
      .post(
        `http://localhost:8000/api/partenaire/Offer/${initialValues.id}/update`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "image/jpeg",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data?.startsWith("offre ne")) {
          toast.update(id, {
            render: res.data,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          return;
        }
        toast.update(id, {
          render: res.data,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        close();
      })
      .catch((err) => {
        console.error(err);
        toast.update(id, {
          render: err?.response?.data?.message || "erreur",
          type: "error",
          autoClose: 1000,
          isLoading: false,
        });
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            defaultValue={initialValues.nom}
            name="nom"
            label="Nom"
          />
          <Controls.Input
            defaultValue={initialValues.description}
            name="description"
            label="Description"
          />
          <Controls.Input
            defaultValue={initialValues.adress}
            label="Adresse"
            name="adress"
          />
          <Controls.Input
            defaultValue={initialValues.reduction}
            label="Réduction"
            name="reduction"
            type="number"
          />
          <Controls.Input
            defaultValue={initialValues.tel}
            type="number"
            label="Mobile"
            name="tel"
          />

          <Controls.Input
            defaultValue={initialValues.prix}
            label="Prix"
            name="prix"
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="Date début"
            name="dateDebut"
            onChange={(e) => setDateDebut(e.target.value)}
            value={dateDebut}
          />
          <DatePicker
            label="Date fin"
            name="dateFin"
            onChange={(e) => setDateFin(e.target.value)}
            value={dateFin}
          />
          <div>
            <Button htmlFor="image" component="label">
              Choisir photo
            </Button>
            <input
              onChange={(e) => setFile(e.target.files[0])}
              id="image"
              name="image"
              type="file"
              style={{ visibility: "hidden" }}
            />
            {initialValues.image && (
              <img
                height="100px"
                src={"http://localhost:8000/uploads/" + initialValues.image}
              />
            )}
            {file && <img height="100px" src={URL.createObjectURL(file)} />}
          </div>

          <div>
            <Controls.Button text="Sauvegarder" type="submit" />
            <Controls.Button text="Reset" color="default" />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}