import axios from "axios";
import NavPart from "../components/partenaire/PartMenu/NavPart";
import PartTransaction from "../components/partenaire/PartMenu/PartTransaction";
import {
  TextField,
  Paper,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Table,
  TableHead,
  TableContainer,
  makeStyles,
} from "@material-ui/core";
import Controls from "../components/partenaire/PartMenu/controls/Controls";

import { useEffect, useState } from "react";
import { useAuth } from "../AuthContext";

const useStyles = makeStyles(theme => ({
  table: {
   
      '& TableHead': {
        marginTop: theme.spacing(3),
          fontWeight: '600',
          color: '#fff',
          backgroundColor: 'rgba(240, 14, 14, 0.717)',
      },
      '& TableCell': {
          fontWeight: '300',
      
      },
      '& tbody tr:hover': {
          backgroundColor: '#fff',
          cursor: 'pointer',
      },
  },
}))
function Transaction() {
  const [data, setData] = useState(null);

  const { token, isSignedIn } = useAuth();
  console.log(token);
  useEffect(() => {
    axios
      .get("http://localhost:8000/api/partenaire/Transaction", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => setData(res.data));
  }, []);
  if (!isSignedIn) return <h1>Not Logged In</h1>;
  return (
    <>
      <NavPart />
      {data && (
        <TableContainer>
          <Table>
            <TableHead > 
              <TableCell>Nom</TableCell>
              <TableCell>Tel</TableCell>
              <TableCell>Number</TableCell>
            </TableHead>
            <TableBody>
              {data.map((e) => (
                <TableRow>
                  <TableCell>{e.nom}</TableCell>
                  <TableCell>{e.tel}</TableCell>
                  <TableCell>{e.number}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
export default Transaction;