import React from "react";
import { getComments, getOrders } from "./API";
import { BellFilled, MailOutlined } from "@ant-design/icons";
import { Badge, Drawer, Image, List, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import './Notifica.css';
import axios from "axios"


function Notification() {

  const [orders, setOrders] = useState([]);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  
  useEffect(() => {
    
    axios.get(`http://localhost:8000/api/adherent/notification/`).then((res) => {
      setOrders(res.data);
    });
  }, []);
  
  return (
    <>
    <div className="">
      <Space>
        < Badge count={orders.length}>
          <BellFilled
            style={{ fontSize: 24  }}
            className= 'notif'
            onClick={() => {
              setNotificationsOpen(true);
            }}
          />
        </Badge>
      </Space>
      <Drawer
        title="Notifications"
        className="draww"
        open={notificationsOpen}
        onClose={() => {
          setNotificationsOpen(false);
        }}
        maskClosable
      >
        <List
        className="drawww"
          dataSource={orders}
          renderItem={(item) => {
            return (
              <List.Item>
                <div className="inBlock">
                  <div className="cont">
                    
                    <img src="assets/yooreed.png"  width={35} height={22.5} />

                  
                    <div className="Title">
                      <h3> {item.nom }</h3>
                      
                      
                    </div>
                  </div>
                  <Typography.Text >{item.description}</Typography.Text>
                </div>
                
              </List.Item>
            );
          }}
          
        ></List>
      </Drawer>
      
    </div>

    </>
  );
}

export default Notification;