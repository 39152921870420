import React, { useEffect, useState } from 'react';
import "./pub.css";
import Publicit from "./publiciteee"
import axios from 'axios';

function Publicite() {



  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 800 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }
  const [showChars, setShowChars] = useState([]);
  const [publicites, setPublicite] = useState([]);

  useEffect(() => {
    const chars = document.querySelectorAll('.slide-title .char');

    chars.forEach((char, index) => {
      setTimeout(() => {
        char.style.opacity = '1';
        char.style.transform = 'translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0)';
      }, 50 * index);
    });

    setShowChars(Array.from(chars));
  }, []);
  
  useEffect(() => {
    axios
      .get(`http://localhost:8000/api/adherent/publicite`)
      .then((response) => {
        setPublicite(response.data);
      })
      .catch((error) => {
        setPublicite([]);
      });
  }, []);

  return (  
    <div className="Publicite">
      <div className='juridique'>
        <h3 style={{ textAlign: "center", margin: 15, fontSize: 18.5 }} className="slide-title">
          <span className="char char1">P</span>
          <span className="char char2">o</span>
          <span className="char char3">u</span>
          <span className="char char4">r</span>
          <span className="char char5"> &nbsp;</span>
          <span className="char char6">p</span>
          <span className="char char7">l</span>
          <span className="char char8">u</span>
          <span className="char char9">s</span>
          <span className="char char10">&nbsp;</span>
          <span className="char char11">d'</span>
          <span className="char char12">i</span>
          <span className="char char13">n</span>
          <span className="char char14">f</span>
          <span className="char char15">o</span>
          <span className="char char16">r</span>
          <span className="char char17">m</span>
          <span className="char char18">a</span>
          <span className="char char19">t</span>
          <span className="char char20">i</span>
          <span className="char char21">o</span>
          <span className="char char21">n</span>
          <span className="char char21">s</span>

        </h3>
        <p style={{ textAlign: "left", margin: 5 }}> Ben Ghalba Souhir</p>
        <p style={{ textAlign: "left", margin: 5 }}> +216 25 908 713</p>
      </div>


    <div className="publ">   
      <Publicit/>
    </div> 
      
  </div>
  )
}

export default Publicite;
