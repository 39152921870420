import Navbar from "../components/Navbar"

function Evenement (){
    return(
        <>
              <Navbar/>

        </>

    )
}
export default Evenement;