import Navbar from "../components/Navbar"

import SlideApropos from "../components/SlideApropos";
import ImageContact from "../components/ImageContact"
import conimg from "../components/assets/imgCont.jpg";
import App11 from "../components/aprposSl";
import Footer from "../components/Footer"
import Footr from "../components/Footr"
function A_propos (){
    return(
        <>
              <Navbar/>
              { <ImageContact               
               cName="main1"
               image={conimg} 
               overlay-img

               title="A Propos"
              
              
             />}

                {/* <SlideApropos/> */}

                <App11/>

                <Footr/>
                <Footer/>
             

        </>

    )
}
export default A_propos;