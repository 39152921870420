import React from "react";

import "./Details.css";

export const Details = ({
  
  title,
  description,
  description1,
  lieu,

}) => {
  return (
    <div className="details-container">
      
      {title && <>
      <h2 className="details-title">{title}</h2>
      <br/>
      </>}
      {description1 && <p className="details-description">{description1}</p>}
      {description && <p className="details-description">{description}</p>}
      {lieu && <p className="details-description">{lieu}</p>}
      
    </div>
  );
};