import React, { useEffect, useState } from 'react';
import { Menu } from "antd";
import "./pubYooreed.css";
import Publicit from "./publiYooreed"


function PubliciteYooreed() {

  const [showChars, setShowChars] = useState([]);
  const [publicites, setPublicite] = useState([]);

  useEffect(() => {
    const chars = document.querySelectorAll('.slideTitle .char');

    chars.forEach((char, index) => {
      setTimeout(() => {
        char.style.opacity = '1';
        char.style.transform = 'translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0)';
      }, 50 * index);
    });

    setShowChars(Array.from(chars));
  }, []);
 
  return (  
    <div className="Publicites">
      <div className='juridiques'>
        <h3 style={{ textAlign: "center", margin: 15, fontSize: 21 }} className="slideTitle">
          <span className="char char1">P</span>
          <span className="char char2">o</span>
          <span className="char char3">u</span>
          <span className="char char4">r</span>
          <span className="char char5"> &nbsp;</span>
          <span className="char char6">p</span>
          <span className="char char7">l</span>
          <span className="char char8">u</span>
          <span className="char char9">s</span>
          <span className="char char10">&nbsp;</span>
          <span className="char char11">d'</span>
          <span className="char char12">i</span>
          <span className="char char13">n</span>
          <span className="char char14">f</span>
          <span className="char char15">o</span>
          <span className="char char16">r</span>
          <span className="char char17">m</span>
          <span className="char char18">a</span>
          <span className="char char19">t</span>
          <span className="char char20">i</span>
          <span className="char char21">o</span>
          <span className="char char21">n</span>
          <span className="char char21">s</span>

        </h3>
        <p style={{ textAlign: "center", margin:12 }}> +216 73 208 173</p>
        <p style={{ textAlign: "center", margin: 12 }}> +216 73 208 170</p>
      </div>


      <div className="publi">   
      <Publicit/>
    </div> 
    </div>
  )
}

export default PubliciteYooreed

