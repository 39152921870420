import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Im4 from "./assets/4.png"
import Im5 from "./assets/5.png"
import './aproposSl.css';
import  { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function aprposSl() {


    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 800 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      }

     
  return (
    <>
    <div className="main2"> <h1>PRESENTATION YOOREED</h1> </div>
        
        < img src={Im4}  className="imag4" data-aos="zoom-in-right" />
        <div className="main5">
        <Carousel responsive={responsive} className="proParag" showDots={true} customTransition="all .5" autoPlaySpeed={5000}
           autoPlay={true} infinite={true}>

        <div>
        <p>Yooreed c'est une société des services et développement des applications numériques, fondé en 2019. 
            <br/>Yooreed agit comme intermédiaire entre les fournisseurs des services et les clients (les groupements de personnel <br/> amicaux , les associations, les ordres des métiers etc...). <br/> en créant une plateforme qui permet aux clients de consulter et de profiter des offres proposées par les partenaires.<br/> Il existe également des cartes numériques proposées par Yooreed qui sont dédiées à chaque client appartenant<br/> à une amicale ou autre groupement socio-professionnel qui lui permet d accéder aux divers services de la société<br/> Yooreed à travers son site web ou ses réseaux sociaux actifs.</p>
       
        </div>
        <div>
        <p> Yooreed ne sera pas seulement une solution et intermédiaire de E-commerce.<br/> Elle est en train de bâtir son Holding entreprenariat pour fournir 
        à ses collaborateurs, adhérant et consommateurs <br/>plus d'avantages et réduction sur divers produits et services.<br/>
        NB<br/>
        Les amicaux et les entreprises privées et publiques sont liées par des contrats de collaboration.<br/>
        L’objet de ces contrats est le multiservice destiné au profit des salariés, 
        sauf que, jusqu'à maintenant, ces contrats <br/> sont hors visibilité des salariés,
         et sans garanties de satisfaction exigées.</p>
         </div>
        
        
        </Carousel>
        <img src={Im5}  className="imag5" data-aos="zoom-in-left" />
        </div>

    </>
  )
}

export default aprposSl