import "./FormContact.css";
import Img4 from "./assets/img4.png";
import Local from "./assets/local.png"
import telephone from "./assets/tel.png"
import messagee from "./assets/msg.png"
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import swal from 'sweetalert'
import AOS from 'aos';
import 'aos/dist/aos.css';
import  { useState, useEffect } from 'react';
  function Formulaire (props){

    
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_xnt1hq5', 'template_164bgmo', form.current, 'eQbEwMHZse_ZCRU0r')
      .then((result) => {
        swal("Success", "", "success");
        console.log(result.text);
    }, (error) => {
        swal("messing field", "", "error");

        console.log(error.text);
    });
    e.target.reset()
  };

  useEffect(() => {
    AOS.init({
      duration:2000
    });
  },[]);
  
    return(
        <>
         <img src={Img4}  className="image1" data-aos="zoom-in"/>
        <tr className="container-position">
                <td>
                    <div className="from-container">               
                        < form className="form1" ref={form} onSubmit={sendEmail}>
                            <input placeholder="Nom Et Prenom" type="text" className="input1"  name="user_name" required />
                            <input placeholder="Email" type="email" className="input1"  name="user_email" required />                           
                            <input placeholder="Sujet" className="input1" type="text"  name="user_subject" required />               
                            <textarea placeholder="Message" type="text" rows="8"  name="user_message" required />
                            <button type="submit">Envoyer</button>
                        </form>
                    </div>
                </td>
                <td>
                    <tr>
                        <td>
                            <div className="loc" data-aos="fade-left"  >
                                <br className="b1b"/> <br className="b1b"/> <br className="b1b"/> <br/> <br/>
                                < img src={Local}  className="localisa" />
                                <img src={telephone}  className="localis"  />                               
                                <img src={messagee}  className="localis"  />
                            </div>
                        </td>                                       
                    <td>  
                    
                    <br className="b1b"/>          
                        <div className="local">
                                            
                            <br/> <br/><br/><h3 >&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;Avenue Colonel Bjaoui Immeuble Bouhaha 3éme Etage</h3>
                            <br/> <br/> <br/> <br/><h3 >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+216 70 605 610 &nbsp;           +216 70 605 611</h3>
                            <br/> <br/> <br/> <br/><br className="b1b"/> <h3 >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email: Contact@yooreed.com.tn</h3>
                            <br/> <br/> <br/> <br/> <br/><br/><br/>
                        </div>
                    </td>
                    
            </tr>
            </td>
        </tr>
             <img src={Img4}  className="image227" width={100} height={100} data-aos="zoom-in"/>
       
        </>

    );
}
export default Formulaire;
