export const NavItems =[
    {
        title :"Vos offres",
        url:"/posteoffer",
        cName:"nav-links",
      
    },
    

    {
        title :"Transactions",
        url:"/transaction",
        cName:"nav-links",
        
    },

  

]