import NavPart from "../components/partenaire/PartMenu/NavPart";
import React, { useEffect, useState } from "react";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import PageHeader from "./PageHeader";
import PartAcceuil from "../components/partenaire/PartMenu/PartAcceuil";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import "./PosteOffer.css"
import {
  TextField,
  Paper,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Table,
} from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import useTable from "./useTable";
import Controls from "../components/partenaire/PartMenu/controls/Controls";
import Popup from "./Popup";
import OfferForm from "./OfferForm";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { toast } from "react-toastify";
import EditOfferForm from "./EditOfferForm";

const headCells = [
  { id: "", label: "Photo" },
  { id: "fullName", label: "Nom" },
  { id: "email", label: "Description " },
  { id: "adress", label: "Addresse" },
  { id: "department", label: "Réduction" },
  { id: "department", label: "Mobile" },
  { id: "prix", label: "Prix", disableSorting: true },
  { id: "date_debut", label: "Date début", disableSorting: true },
  { id: "date_fin", label: "Date fin", disableSorting: true },
  { id: "actions", label: "Actions", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    width: "50%",
    marginLeft: "20%",
    height: "7%",
    marginTop: "-10%",
    margin: " theme.spacing(5)",
    display: "auto",
    position: "absolute",
    padding: "theme.spacing(3) ",
  },
  searchInput: {
    top: "20%",
    width: "60%",
    left: "1%",
    display: "auto",
    position: "absolute",
  },

  newButton: {
    position: "absolute",
    border: "1px solid  rgba(240, 14, 14, 0.717)",
    color: " rgba(240, 14, 14, 0.717)",
    left: "8%",

    top: "30%",
  },
}));

function Posteoffer() {
  const [searchValue, setSearchValue] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [editOffer, setEditOffer] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const { token, isSignedIn } = useAuth();

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);

  const [partenaires, setPartenaires] = useState([]);
  console.log(partenaires);

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/partenaire/Offer", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => setPartenaires(data));
  }, [token]);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    if (refetch)
      axios
        .get("http://localhost:8000/api/partenaire/Offer", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(({ data }) => {
          setPartenaires(data);
          setRefetch(false);
        });
  }, [refetch]);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(partenaires, headCells, filterFn);
  const classes = useStyles();
  const addOrEdit = (employee, resetForm) => {
    if (employee.id == 0) console.log("hell");
    // employeeService.insertEmployee(employee)
    // employeeService.updateEmployee(employee)
    else console.log("hello");
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setRecords([]);
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const handleDelete = (id) => async () => {
    const toastId = toast.loading("Suppression ...");

    try {
      const { data } = await axios.get(
        `http://localhost:8000/api/partenaire/Offer/${deleteId}/delete`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setRefetch(true);
      toast.update(toastId, {
        render: data,
        isLoading: false,
        type: "success",
        autoClose: 2000,
      });
      setOpenDeleteModal(false);
    } catch (error) {
      toast.update(toastId, {
        render: error?.response?.data?.message || "Erreur",
        isLoading: false,
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const search = (data, searchValue) => {
    return data.filter((item) => {
      let match = false;
      Object.keys(item).forEach((key) => {
        match = match || `${data[key]}`?.toLowerCase().startsWith(searchValue);
      });
      return match;
    });
  };
  const filteredItems = (searchTerm, items) => {
    const regex = new RegExp(searchTerm, "i");
    return items.filter((item) =>
      Object.values(item).find((value) => regex.test(value))
    );
  };

  console.log(filteredItems(searchValue, partenaires));
//console.log("-----------------------------------------", data);

  if (!isSignedIn) return <h1>n'est pas connecter </h1>;

  return (
    <>
      <NavPart />
      <div className="postoffer">
        <PageHeader
          title="Ajouter offre"
          subTitle="Nouveaux offres pour les partenaires"
          icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
        />
        <Popup
          title="Ajouter Offre"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <OfferForm
            close={() => setOpenPopup(false)}
            recordForEdit={recordForEdit}
            addOrEdit={addOrEdit}
          />
        </Popup>

        <Popup
          openPopup={openEditModal}
          setOpenPopup={setOpenEditModal}
          title={`Modifier Offre #${editOffer?.id}`}
        >
          <EditOfferForm
            close={() => setOpenEditModal(false)}
            initialValues={editOffer}
          />
        </Popup>

        <Popup
          title={`Supprimer Offre #${deleteId} ?`}
          openPopup={openDeleteModal}
          setOpenPopup={setOpenDeleteModal}
        >
          <div>
            <Button
              onClick={handleDelete(deleteId)}
              variant="fill"
              style={{
                backgroundColor: "#ff4081",
                color: "white",
                marginRight: 15,
              }}
            >
              Confirmer
            </Button>
            <Button
              onClick={() => {
                setDeleteId(null);
                setOpenDeleteModal(false);
              }}
              variant="outline"
            >
              Annuler
            </Button>
          </div>
        </Popup>

        <div>
          <Paper className={classes.pageContent}>
            <TextField
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              variant="outlined"
              label="Rechercher"
              className={classes.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
              }}
              variant="outlined"
            >
Ajouter            </Button>
          </Paper>
          <TblContainer>
            <TblHead />
            <TableBody>
              {filteredItems(searchValue, partenaires)
                .filter((p) => p.stat === 1)
                .map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <img
                        style={{ borderRadius: "50%" }}
                        src={"http://localhost:8000/uploads/" + item.image}
                        height={30}
                        width={30}
                        alt=""
                      />
                    </TableCell>
                    <TableCell>{item.nom}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.adress}</TableCell>
                    <TableCell>{item.reduction}</TableCell>
                    <TableCell>{item.tel}</TableCell>
                    <TableCell>{item.prix}</TableCell>
                    <TableCell>{item.dateDebut}</TableCell>
                    <TableCell>{item.dateFin}</TableCell>
                   { item.permanent === 0 && (
                    <TableCell>
                      {/* <Controls.ActionButton
                        onClick={() => {
                          setOpenEditModal(true);
                          setEditOffer(item);
                        }}
                        color="primary"
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton> */}
                      <Controls.ActionButton
                        onClick={() => {
                          setOpenDeleteModal(true);
                          setDeleteId(item.id);
                        }}
                        color="secondary"
                      >
                        <CloseIcon fontSize="small" />
                      </Controls.ActionButton>
                    </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </TblContainer>
        </div>
      </div>
    </>
  );
}
export default Posteoffer;