

import"./Contact.css"
import{ImLocation}from 'react-icons/im';
import{FaRegCalendarAlt}from 'react-icons/fa';
import{IoIosMail}from 'react-icons/io';
import{ImPhone}from 'react-icons/im';

const Contact = ()=> {
    return (
        <div className="Contact">
            
                       <div className="overlay-imgg" >   </div> 
                      

                         <div className="contact-container1">
                         <div className="contact-icons1">
                         
                                 <ImLocation size="3rem" />
                               
                                </div>
                                <div className="contact-text1">
                                  <h3> Avenue Colonel Bjaoui</h3>
                                  <h3>Immeuble Boulhalha 3 Eme  </h3>
                                  <h3>Etage</h3>
                                                           </div>
                                 
                         </div>

                         <div className="contact-container2">
                         <div className="contact-icons2">
                                 
                                 <h2><FaRegCalendarAlt size="3rem"/></h2> </div>
                     
                         
                         <div className="contact-text2">
                                  <h3> Du Lundi Au Vendredi</h3>
                                  <h3> Du 08H00 Au 12H00</h3>
                                  <h3> Et De</h3>
                                  <h3>  14H00 Au 18H00</h3>


                               </div>
                              
                               </div>
                         <div className="contact-container3">
                         <div className="contact-icons3">
                         
                         <h2><IoIosMail size="3rem"/></h2></div>
                         <div className="contact-text3">
                                  <h3>Email: Contact@yooreed.com</h3>
                               </div>
                               
                         </div>
                         
                         <div className="contact-container4">
                         <div className="contact-icons4">
                         
                         <h2><ImPhone size="3rem"/></h2> </div>
                         <div className="contact-text4">
                                  <h3> +216 73 208 173</h3>
                                  <h3> +216 73 208 170</h3>

                               </div>
                               
                         </div>
                        
                         
            
            
                                  
       
            
            </div>
            
    );
};
export default Contact;
