import "./Login.css";
import image111 from "./assets/4.png";
import emails from "./assets/email.jpg";
import { toast } from "react-toastify";

import yoor from "./assets/yooreed.png";

import { Link, Navigate, useNavigate } from "react-router-dom";

import { useState } from "react";
import axios from "axios";
import { useAuth } from "../AuthContext";
import {
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
} from "@material-ui/core";

import Controls from "./partenaire/PartMenu/controls/Controls";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { ToggleButton } from "@mui/material";
const LOGIN_URL = {
  PARETENAIRE: "http://localhost:8000/api/partenaire/auth",
  ADHERENT: "http://localhost:8000/api/adherent/auth",
};

function Log() {
  const navigate = useNavigate();
  const [loginUrl, setLoginUrl] = useState(LOGIN_URL.PARETENAIRE);
  const [formData, setFormData] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const { setToken, setIsSignedIn, token, isSignedIn, setUserRole } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loginFormData = new FormData();
    loginFormData.append("email", formData.email);
    loginFormData.append("password", formData.password);

    const toastId = toast.loading("Loading ...");
    try {
      const { data } = await axios.post(loginUrl, loginFormData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      console.log("--------------> DATA: ",data);
    
    
    console.log("--------------> Size DDATA: ", JSON.stringify(data).length);
      //if (typeof data === "string" && data.toLowerCase().startsWith("f")) {
        if(JSON.stringify(data).length==30){
        console.log("--------------> Cas 1");
        return toast.update(toastId, {
          isLoading: false,
          autoClose: 10000,
          type: "error",
          render: "Mot de passe Incorrect !",
        });
      }

      toast.update(toastId, {
        isLoading: false,
        autoClose: 10000,
        type: "success",
        render: "Connecté avec succès",
      });

      setToken(data.token);
      setIsSignedIn(true);
      setUserRole(
        loginUrl === LOGIN_URL.PARETENAIRE ? "partenaire" : "adherent"
      );

      if (loginUrl === LOGIN_URL.PARETENAIRE) navigate("/posteoffer");
      else navigate("/adherent");
    } catch (error) {
      toast.update(toastId, {
        isLoading: false,
        autoClose: 10000,
        type: "error",
        render: error?.response?.data?.message || "Erreur est survenu",
      });
      console.error(error);
    }
  };

  return (
    <div class="contxx">
      <div className="subb-mainn">
        <img
          src={yoor}
          style={{ height: 50, width: 50, margin: "0 auto", marginBottom: 20 }}
        ></img>

        <div>
          <div className="connecttt">
            <h1>Se Connecter</h1>
          </div>
          <div className="ssss">
            <ToggleButton
              sx={{ mr: 2 }}
              size="sm"
              selected={loginUrl === LOGIN_URL.PARETENAIRE}
              onClick={() => setLoginUrl(LOGIN_URL.PARETENAIRE)}
            >
              Partenaire
            </ToggleButton>

            <ToggleButton
              selected={loginUrl === LOGIN_URL.ADHERENT}
              onClick={() => setLoginUrl(LOGIN_URL.ADHERENT)}
            >
              Adherent
            </ToggleButton>
          </div>

          <form onSubmit={handleSubmit}>
            <div>
              <TextField
                name="email"
                value={formData.email}
                onChange={handleChange}
                label="Email"
                variant="outlined"
                fullWidth
              />
            </div>
            <div style={{ marginBottom: 10 }} className="secondd-inputt">
              <FormControl
                fullWidth
                sx={{ m: 1, width: "25ch" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Mot de passe
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handleChange}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </div>

            <Button type="submit" variant="contained" size="large" fullWidth>
              Submit
            </Button>
            <p className="linkkk">
              <a>Mot de passe oublieé ?</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Log;