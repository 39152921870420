import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "./Card.css";
import { useNavigate } from "react-router-dom";

export const Card = ({
    icon,
    title,
  
}) => {
  const navigate = useNavigate();
  const handleNavigation = () =>{
    navigate(`/${title}`);
  }
  return (
    <div className="card-container" onClick={handleNavigation}>
      
      {icon &&
        <FontAwesomeIcon icon={icon} className="card-img"/>
      }
      {title && <h3 className="card-title">{title}</h3>}
      
    </div>
  );
};
