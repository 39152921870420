
import"./YooreedData2.css"
const YooreedData2 = ()=> {
    return (
        <div className="yooreedData2">
          
                        <h1>03</h1>
                        <h3> NOS SERVICES </h3>
                       <div className="service2">
                         <div className="service-text">
                           <h2>Définition des services </h2> 
                           
                            </div>
                      
                      </div>
                      
            </div>
     
    );
};
export default YooreedData2;

