import NavB from "../components/adherent/NavB"
import New from "../components/adherent/New"
import ProfilConnect from "../components/adherent/profilConnect"
function Profil (){
    return(
        <>
            <NavB/>
            <ProfilConnect/>

        </>


    );
}
export default Profil;