import NavPart from "./NavPart"
import"./PartTransaction.css"
import React, { useState } from 'react'
import Data from './data2.json'
function PartTransaction() {
 
  return (
 
    
       <div className="parttransaction">
        
             <div>
             
             </div>
   
         
    
                
                        
                                            
    </div>
    
 

  )
}

export default PartTransaction;