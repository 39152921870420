import React, { useState ,useEffect} from "react";
import styled from "styled-components";
import { Space } from "antd";
import { Avatar } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import "./profi.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../AuthContext";
import AOS from 'aos';
import 'aos/dist/aos.css';
const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
`;

export default function App() {
  const { logOff } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);
  return (
    <>
      <div className="DropDownContainer">
        <div className="DropDownHeader" onClick={toggling}>
          <Space>
            <Avatar
              // shape="square"
              style={{ fontSize: 20 }}
              icon={<UserOutlined className="profil" />}
              className="profii"
            />
          </Space>
        </div>
        {isOpen && (
          <div classNma="DropDownListContainer" data-aos="fade-left">
            <ul className="DropDownList">
              <ListItem>
                <div className="cont">
                  <Link
                    className="Title"
                    to="/partenaire/part/profile"
                    style={{ color: "black" }}
                  >
                    <h3>
                      <SettingOutlined /> &nbsp;&nbsp;&nbsp; Compte{" "}
                    </h3>
                  </Link>
                </div>
              </ListItem>
              <ListItem>
                <div className="cont">
                  <div className="Title">
                    <h3 style={{ cursor: "pointer" }} onClick={() => logOff()}>
                      <LogoutOutlined />
                      &nbsp;&nbsp;&nbsp; Déconnexion
                    </h3>
                  </div>
                </div>
              </ListItem>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}