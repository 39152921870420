import React from 'react'
import Img3 from "./assets/img3.png"
import ReactPlayer from 'react-player'

import"./YooreedData.css"

function YooreedData  () {
  
  const externalSource="https://www.youtube.com/watch?v=ysz5S6PUM-U";
  const internalSource="assets/yooreed.mp4";  
  return (
        <div className="yooreedData">
          
               <div className="image22211">
                  <img alt="img" src= {Img3}/>
                  
                  <h1>02</h1>
                   <h3> CHOISIR YOOREED </h3>
                       <div className="choix">
                         <div className="choix-text">
                           <h2>POURQUOI CHOISIR YOOREED ?</h2> 
                           <p className="pp1">Vous avez besoin d'un intermédiaire qui crée une relation de travail entre clients et fournisseurs des services.</p>
                           <p>Yooreed offre les meilleurs services aux clients et aux fournisseurs des services.
                            </p>
                           </div>
                           <div className="player-wrapper">
                            <ReactPlayer
                              url={internalSource} 
                              controls
                              playing
                              muted
                              className="player"
                            />
                      </div>
                      </div>
                      
                      </div>
            
          </div>
       
     
    );
};
export default YooreedData;

