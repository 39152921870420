import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Accueil from "./routes/Accueil";
import Services from "./routes/Services";
import Partenaires from "./routes/Partenaires";
import Evenement from "./routes/Evenement";
import A_propos from "./routes/A_propos";
import Contact from "./routes/Contact";
import Posteoffer from "./routes/Posteoffer";
import Scanne from "./routes/Scanne";
import Transaction from "./routes/Transaction";
import Alimentation from "./routes/Alimentation";
import Assurence from "./routes/Assurence";
import Education from "./routes/Education";
import Loisir from "./routes/Loisir";
import Sante from "./routes/Sante";
import Technologie from "./routes/Technologie";
import Tourisme from "./routes/Tourisme";
import Transport from "./routes/Transport";
import Adherent from "./routes/Adherent";
import News from "./routes/News";
import OffresYooreed from "./routes/OffresYooreed";
import Profil from "./routes/Profil";
import Profile from "./routes/Profile";
import Login from "./routes/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import AuthProvider from "./AuthContext";
export default function App() {
  return (
    <AuthProvider>
      <div className="App">
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Partenaires" element={<Partenaires />} />
          <Route path="/Evenement" element={<Evenement />} />
          <Route path="/A_propos" element={<A_propos />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/posteoffer" element={<Posteoffer />} />
          <Route path="/scanne" element={<Scanne />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route exact path="/sante" element={<Sante />}></Route>
          <Route exact path="/Alimentation" element={<Alimentation />}></Route>
          <Route exact path="/Education" element={<Education />}></Route>
          <Route exact path="/Loisir" element={<Loisir />}></Route>
          <Route exact path="/Technologie" element={<Technologie />}></Route>
          <Route exact path="/Tourisme" element={<Tourisme />}></Route>
          <Route exact path="/Transport" element={<Transport />}></Route>
          <Route exact path="/Assurence" element={<Assurence />}></Route>
          <Route path="/adherent" element={<Adherent />} />
          <Route path="/news" element={<News />} />
          <Route path="/offresyooreed" element={<OffresYooreed />} />
          <Route path="/adherent/profil" element={<Profil />} />
          <Route path="/login" element={<Login />} />
          <Route path="/partenaire/part/profile" element={<Profile />} />
       
        </Routes>
      </div>
    </AuthProvider>
  );
}
