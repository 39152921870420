import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AuthContext = createContext({
  token: null,
  isSignedIn: false,
});

export default function AuthProvider({ children }) {
  const [token, setToken] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [shouldEmptyStorage, setShouldEmptyStorage] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("userRole", userRole);
    }
  }, [token, userRole]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsSignedIn(true);
      setToken(token);
      setUserRole(localStorage.getItem("userRole"));
    }
  }, []);

  const logOff = () => {
    setShouldEmptyStorage(true);

    setIsSignedIn(false);
    setToken(null);
    setUserRole(null);
    toast("Logged Off");
    navigate("/login");
  };

  useEffect(() => {
    if (shouldEmptyStorage) {
      localStorage.removeItem("token");
      localStorage.removeItem("userRole");
      setShouldEmptyStorage(false);
    }
  }, [shouldEmptyStorage]);

  return (
    <AuthContext.Provider
      value={{
        token,
        isSignedIn,
        setToken,
        setIsSignedIn,
        userRole,
        logOff,
        setUserRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}