import React from 'react'
import NavPart from "../components/partenaire/PartMenu/NavPart"
import ProfilConnect from "../components/partenaire/PartMenu/profilConnect"

function Profile() {
  return (
    <>
      <NavPart/>
      <ProfilConnect/>
    </>  
  )
}

export default Profile