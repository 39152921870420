import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from 'react';
import "./pubYooreed.css";
import axios from 'axios';
import styled from "styled-components";

const Container = styled.div`
  width: 27vw;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto  auto auto -8px;
  cursor: pointer;
  opacity: 0.4;
  z-index: 2;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #${(props) => props.bg};
`;

const MediaContainer = styled.div`
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
width: 360px;
max-height:200px;
height: auto;
border-radius: 20px;

`;

const Video = styled.video`
width: 290px;
height: auto;
border-radius: 20px;
`;



const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [publicites, setPublicite] = useState([]);

  useEffect(() => {
    axios
      .get(`http://localhost:8000/api/adherent/Publicite`)
      .then((response) => {
        setPublicite(response.data);
      })
      .catch((error) => {
        setPublicite([]);
      });
  }, []);

  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : publicites.length - 1);
    } else {
      setSlideIndex(slideIndex < publicites.length - 1 ? slideIndex + 1 : 0);
    }
  };

  return (
    <Container>
      <Arrow direction="left" onClick={() => handleClick("left")}>
        <ArrowLeftOutlined />
      </Arrow>
      <Wrapper slideIndex={slideIndex}>
        {publicites.map((item, index) => (
          <Slide key={item.id} bg={item.bg}>
            <MediaContainer>
              {item.file.includes('.mp4') ? (
                <Video src={`http://localhost:8000/uploads/${item.file}`} autoPlay loop muted />
              ) : (
                <Image src={`http://localhost:8000/uploads/${item.file}`} />
              )}
            </MediaContainer>
            
          </Slide>
        ))}
      </Wrapper>
      <Arrow direction="right" onClick={() => handleClick("right")}>
        <ArrowRightOutlined />
      </Arrow>
    </Container>
  );
};

export default Slider;
