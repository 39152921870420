import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './smenu.css';
import swal from 'sweetalert';

function Gouvernorat({ setResults }) {
  const [users, setUsers] = useState([]);
  const [showLinks, setShowLinks] = useState(false);
  const [selectedGouvernorat, setSelectedGouvernorat] = useState('');
  const [selectedSecteur, setSelectedSecteur] = useState('');
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState('');
  const [error, setError] = useState(false); // Nouvelle variable d'état pour l'erreur

  useEffect(() => {
    fetchData();
  }, []);

  const handellShowLinks = () => {
    setShowLinks(!showLinks);
  };

  const fetchData = () => {
    const token = localStorage.getItem('token'); // Replace this with how you store your token
    axios
      .get('http://localhost:8000/api/adherent/gov', {
        headers: {
          Authorization: `Bearer ${token}`, // Use your token here
          Accept: 'application/json',
        },
      })
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log('Error fetching gouvernorats:', err.response ? err.response.data : err.message);
        if (err.response && err.response.status === 401) {
          // Handle unauthorized error (optional)
          swal('Unauthorized access, please login', '', 'warning');
        }
      });
  };

  const handleFilter = async (gov, secteur) => {
    try {
      const response = await axios.post(
        'http://localhost:8000/api/adherent/offer/filterPermanent',
        {
          gov: gov,
          secteur: secteur,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data.length === 0) {
        setError(true);
        swal('Aucune offre disponible', '', 'warning');
      } else {
        setError(false);
        setResults(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
      setError(true);
      swal('Erreur lors du filtrage des offres', '', 'error');
    }
  };
  

  useEffect(() => {
    axios
      .get('http://localhost:8000/api/adherent/secteur')
      .then((response) => {
        setOptions(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleGouvernoratChange = (event) => {
    const selectedGov = event.target.value;
    setSelectedGouvernorat(selectedGov);
    handleFilter(selectedGov, selectedSecteur);
    console.log('Selected Gouvernorat:', selectedGov);
  };

  const handleChange = (event) => {
    const selectedSect = event.target.value;
    setSelectedSecteur(selectedSect);
    handleFilter(selectedGouvernorat, selectedSect);
    console.log('Selected Secteur:', selectedSect);
  };

  return (
    <div className={`SideMenu ${showLinks ? 'showNavBarr' : 'hide-nav'}`}>
      <button className="sideBarBurger" onClick={handellShowLinks}>
        <span className="burger"></span>
      </button>
      <div className="ConMenu">
        <div className="listGovAdh slideInDownnn-1">
          <h3 style={{ color: '#E9564B', display: 'flex', paddingBottom: '20px' }}>
            <span style={{ fontSize: '23px' }}> • </span>
            <span style={{ color: 'black', paddingLeft: '5px' }}>Gouvernorat</span>
          </h3>
          <select
            value={selectedGouvernorat}
            id="select-address"
            onChange={handleGouvernoratChange}
            className="selection"
            onClick={fetchData}
          >
            <option value=""> </option>
            {users.map((item) => (
              <option key={item.id} value={item.id}>
                {item.nom}
              </option>
            ))}
          </select>
        </div>
        <div className="listGovAdh slideInDownnn-3">
          <h3 style={{ color: '#E9564B', display: 'flex', paddingTop: '20px', paddingBottom: '20px' }}>
            <span style={{ fontSize: '23px' }}> • </span>
            <span style={{ color: 'black', paddingLeft: '5px' }}>Secteur</span>
          </h3>
          {/* {error && swal('Aucune offre disponible', '', 'warning')} */}
          <ul style={{ listStyle: 'none', fontSize: '14px' }}>
            {options.map((option) => (
              <li style={{ paddingBottom: '10px' }}>
                <label key={option.id}>
                  <input
                    type="radio"
                    name="radio-list"
                    value={option.id}
                    onChange={handleChange}
                    className="radio-button"
                    style={{ marginRight: '6px', marginLeft: '8px' }}
                  />
                  {option.nom}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Gouvernorat;
