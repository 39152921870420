import React, { useEffect, useState } from 'react';
import './SlidePropos.css';
import Im4 from "./assets/4.png"
import Im5 from "./assets/5.png"

function SlideApropos ()  {
  
    const words=[
        {id:0,value:
          <p>Yooreed c'est une société des services et développement des applications numériques, fondé en 2019. <br/>Yooreed agit comme intermédiaire entre les fournisseurs des services et les clients (les groupements de personnel amicaux , les associations, les ordres des métiers etc...). en créant une plateforme qui permet aux clients de consulter et de profiter des offres proposées par les partenaires.<br/> Il existe également des cartes numériques proposées par Yooreed qui sont dédiées à chaque client appartenant à une amicale ou autre groupement socio-professionnel qui lui permet d accéder aux divers services de la société Yooreed à travers son site web ou ses réseaux sociaux actifs.</p>},
        {id:1,value:<p> Yooreed ne sera pas seulement une solution et intermédiaire de E-commerce. Elle est en train de bâtir son Holding entreprenariat pour fournir à ses collaborateurs, adhérant et consommateurs plus d'avantages et réduction sur divers produits et services.<br/>
        NB<br/>
        Les amicaux et les entreprises privées et publiques sont liées par des contrats de collaboration.<br/>
        L’objet de ces contrats est le multiservice destiné au profit des salariés, sauf que, jusqu'à maintenant, ces contrats sont hors visibilité des salariés, et sans garanties de satisfaction exigées.</p>},
        

    ];

   
    const [wordData,setWordData]=useState(words[0].value)
    const handleClick=(index)=>{
        console.log(index)
        const wordSlider=words[index].value;
        setWordData(wordSlider)
      }

      // useEffect(()=>{ 
      //   const lastIndex = words.length - 1;
      //   if(wordData < 0){
      //     setWordData(lastIndex);
      //   }

      //   if(wordData > lastIndex){
      //     setWordData(0);
      //   }
      // },[wordData,words]);

    //   useEffect(()=> {
    //     let slider = setInterval(() => {
    //       setWordData(wordData + 1)
    //   } , 5000); 
    // return () => { clearInterval(slider)

    // }}, [wordData]);


  
    return (
    <>
    <div className="main2"> <h1>PRESENTATION YOOREED</h1> </div>
    <img src={Im4}  className="imag4"  />
     <div className="main5">
      <div className="proParag">
        <p> {wordData} </p> </div>
        <div className='flex_row'>
         {/* let position ="nextSlide";             */}

            {words.map((data,i)=>
            <h1 style={{cursor: "pointer" }}   key={i} onClick={()=>handleClick(i)} showDots={true} >.</h1>
            //   if (i === wordData) {
            //   position ="activeSlide";
            // }
            )}
         </div>
         <img src={Im5}  className="imag5"  />

    </div>
    </>
  )
}

export default SlideApropos
