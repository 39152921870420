import axios from 'axios';
import "./new.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useState, useEffect } from 'react';

export default function PageCont({ results }) {

  const [modal, setModal] = useState(false);
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [resultatAPI, setResultatAPI] = useState('');
  const toggleModal = (offer) => {
    setSelectedOffer(offer);
    setModal(!modal);
    if (offer) {
      envoyerParametreAPI(offer.nom);
    }
  };

  const envoyerParametreAPI = async (param) => {
    try {
      const response = await axios.get('http://localhost:8000/api/adherent/News/Offer', {
        params: {
          parametre: param,
        },
      });

      setResultatAPI(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);

  useEffect(() => {
    axios
      .get(`http://localhost:8000/api/adherent/News/Offer`)
      .then((response) => {
        setOffers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching offers:', error);
        setOffers([]);
      });
  }, []);

  return (
    
    <div className="contenueDePage">
      <ul>
          {(results && results.length > 0) ? (
            results.map((offer) => (
              <a className="btn-modal" key={offer.id} data-aos="fade-left">
                <div className="cttt" onClick={() => toggleModal(offer)}>
                  <div className="ctttt">
                    <div className="ctt">
                      <img
                        src={`http://localhost:8000/uploads/${offer.partenaires.image}`}
                        width={46}
                        height={46}
                        padding-top={20}
                        alt={offer.nom}
                      />
                      <div className="Title">
                        <h3>{offer.partenaires.nom}</h3>
                      </div>
                    </div>
                    <div className="contenueOp">
                      <div className="offre">
                        <h3> {offer.nom}</h3>
                      </div>
                      <div className="description">
                        <h3> Description: </h3>
                        <p> {offer.description}</p>
                      </div>
                      
                        <div className="reduction">
                          <h3> Reduction</h3>
                          <h4 className='reduc'> {offer.reduction}% </h4>
                        </div>
                      
                      
                    </div>
                  </div>
                  <div className="imageOffre">
                    <img src={`http://localhost:8000/uploads/${offer.image}`} padding-top={20} />
                  </div>
                </div>
              </a>
            ))
          ) : (
            offers.map((offer) => (
              <a className="btn-modal" key={offer.id} data-aos="fade-left">
                <div className="cttt" onClick={() => toggleModal(offer)}>
                  <div className="ctttt">
                    <div className="ctt">
                      <img
                        src={`http://localhost:8000/uploads/${offer.partenaires.image}`}
                        width={46}
                        height={46}
                        padding-top={20}
                        alt={offer.nom}
                      />
                      <div className="Title">
                        <h3>{offer.partenaires.nom}</h3>
                      </div>
                    </div>
                    <div className="contenueOp">
                      <div className="offre">
                        <h3> {offer.nom}</h3>
                      </div>
                      <div className="description">
                        <h3> Description: </h3>
                        <p> {offer.description}</p>
                      </div>
                      
                        <div className="reduction">
                          <h3> Reduction</h3>
                          <h4 className='reducNew'> {offer.reduction}% </h4>
                        </div>

                          <div className="dateFinie" >
                            <h3> Date Fin</h3> 
                            <h4 className='datee'> {offer.dateFin} </h4>        
                          </div>

                    </div>
                  </div>
                  <div className="imageOffre">
                    <img src={`http://localhost:8000/uploads/${offer.image}`} padding-top={20} />
                  </div>
                </div>
              </a>
            ))
          )}
      </ul>
        {modal && (
        <div className="modal" >
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content" data-aos="slide-in">
            <h2>Voir Plus...</h2>
            <p offer={selectedOffer} resultatAPI={resultatAPI} />
            <div className="descriptionModell">
              <p> <h3>Description:</h3>{selectedOffer.description}</p>
            </div>
            <div className="descriptionModell">
              <p offer={selectedOffer}> <h3>Nom:</h3> {selectedOffer.nom}</p>
            </div>
            <div className="descriptionModell">
              <p> <h3 offer={selectedOffer}>Prix:</h3>{selectedOffer.prix}</p>
            </div>
            <div className="descriptionModell">
              <p> <h3 offer={selectedOffer}>Adresse:</h3>{selectedOffer.adress}</p>
            </div>
            <div className="descriptionModell">
              <p> <h3 offer={selectedOffer}>Télèphone:</h3>{selectedOffer.tel}</p>
            </div>
            <button className="close-modal" onClick={toggleModal}>
              x
            </button>
          </div>
        </div>
      )}

      {/* <div className="cttt">
      <div className="ctttt"> 
        <div className="ctt">
          <img src={Im4}  width={50} height={50} padding-top={20} /> 
          <div className="Title">
            <h3> Aziza</h3>
          </div>
        </div>
        <div>
          <div className="offre">
              <h3> Offre N1</h3>         
          </div>
          <div className="description">          
              <h3> Description: </h3>
              <p>&nbsp;&nbsp; Les amicaux et les entreprises privées et publiques sont liées par des contrats de collaboration.</p>
          </div>
          <div className="reduction" >
              <h3> Reduction</h3>
              <h4 className='reduc'> 20% </h4>               
          </div>
          <div className="dateFinie" >
              <h3> Date Finie</h3> 
              <h4 className='datee'> 12/05/2023 </h4>        
          </div>         
        </div>
      </div> 
      <div>
        <img src={Im}  width={200} height={250} padding-top={20} />
      </div>
      </div>

      <div className="cttt">
      <div className="ctttt"> 
        <div className="ctt">
          <img src={Im4}  width={50} height={50} padding-top={20} /> 
          <div className="Title">
            <h3> Aziza</h3>
          </div>
        </div>
        <div>
          <div className="offre">
              <h3> Offre N1</h3>         
          </div>
          <div className="description">          
              <h3> Description: </h3>
              <p>&nbsp;&nbsp; Les amicaux et les entreprises privées et publiques sont liées par des contrats de collaboration.</p>
          </div>
          <div className="reduction" >
              <h3> Reduction</h3>         
          </div>
        </div>
      </div> 
      <div>
        <img src={Im}  width={200} height={250} padding-top={20} />
      </div>
      </div> */}
    </div>

    
  )
}


