import "./ImageContact.css";

function ImageContact (props){
    return(
        <>
        <div className={props.cName}>
            <div className="ovelay-img" ></div>
            <span> <img src= {props.image } /></span>
            <div className="contenu">
                <h1>{props.title}</h1>
            </div>
        </div>

        

        </>

    );
}
export default ImageContact;