import axios from 'axios';
import "./pcon.css";
import {
  Phone,
  Room, 
} from "@material-ui/icons";
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useState, useEffect } from 'react';

export default function PageContenet({ results }) {
  const [modal, setModal] = useState(false);
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [resultatAPI, setResultatAPI] = useState('');

  const descriptionStyle = {
    WebkitLineClamp: 2, // Limit the description to 2 lines
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box'
  };

  const toggleModal = (offer) => {
    setSelectedOffer(offer);
    setModal(!modal);

    if (offer) {
      envoyerParametreAPI(offer.nom);
    }
  };

  const envoyerParametreAPI = async (param) => {
    try {
      const response = await axios.get('http://localhost:8000/api/adherent/Permanent/Offer/', {
        params: {
          parametre: param,
        },
      });

      setResultatAPI(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);

  useEffect(() => {
    axios
      .get(`http://localhost:8000/api/adherent/Permanent/Offer`)
      .then((response) => {
        setOffers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching offers:', error);
        setOffers([]);
      });
  }, []);

  return (
    <>
      <div className="contenueDePage">
        <ul>
          {(results && results.length > 0) ? (
            results.map((offer) => (
              <a className="btn-modal" key={offer.id} >
                <div className="cttt" onClick={() => toggleModal(offer)} data-aos="fade-left">
                  <div className="ctttt">
                    <div className="ctt">
                      <img
                        src={`http://localhost:8000/uploads/${offer.partenaires.image}`}
                        width={46}
                        height={46}
                        padding-top={20}
                        alt={offer.nom}
                      />
                      <div className="Title">
                        <h3>{offer.partenaires.nom}</h3>
                      </div>
                    </div>
                    <div className="contenueOp">
                      <div className="offre">
                        <h3> {offer.nom}</h3>
                      </div>
                      <div className="description">
                        <h3> Description: </h3>
                        <p> {offer.description}</p>
                      </div>
                      {offer.type === 0 && (
                        <div className="reduction">
                          <h3> Reduction</h3>
                          <h4 className='reduc'> {offer.reduction}% </h4>
                        </div>
                      )}
                      {offer.type === 1 && (
                        <div className="adress">
                          <div className="adress1">
                            <Room style={{ marginRight: "10px", background: "#e9564b", color: "white", borderRadius: "20px", padding: "2px" }} />
                            <h3> Adresse : </h3>
                          </div>
                          <p> &nbsp;{offer.adress}   </p>
                        </div>
                      )}
                      {offer.type === 1 && (
                        <div className="tel">
                          <div className="tel1">
                            <Phone style={{ marginRight: "10px", background: "#e9564b", color: "white", borderRadius: "20px", padding: "2px" }} />
                            <h3> Télèphone :</h3>
                          </div>
                          <p>&nbsp;{offer.tel}  </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="imageOffre">
                    <img src={`http://localhost:8000/uploads/${offer.image}`} padding-top={20} />
                  </div>
                </div>
              </a>
            ))
          ) : (
            offers.map((offer) => (
              <a className="btn-modal" key={offer.id} >
                <div className="cttt" onClick={() => toggleModal(offer)} data-aos="fade-left">
                  <div className="ctttt">
                    <div className="ctt">
                      <img
                        src={`http://localhost:8000/uploads/${offer.partenaires.image}`}
                        width={46}
                        height={46}
                        padding-top={20}
                        alt={offer.nom}
                      />
                      <div className="Title">
                        <h3>{offer.partenaires.nom}</h3>
                      </div>
                    </div>
                    <div className="contenueOp">
                      <div className="offre">
                        <h3> {offer.nom}</h3>
                      </div>
                      <div className="description">
                        <h3> Description: </h3>
                        <p> {offer.description}</p>
                      </div>
                      {offer.type === 0 && (
                        <div className="reduction">
                          <h3> Reduction</h3>
                          <h4 className='reduc'> {offer.reduction}% </h4>
                        </div>
                      )}
                      {offer.type === 1 && (
                        <div className="adress">
                          <div className="adress1">
                            <Room style={{ marginRight: "10px", background: "#e9564b", color: "white", borderRadius: "20px", padding: "2px" }} />
                            <h3> Adresse : </h3>
                          </div>
                          <p> &nbsp;{offer.adress}   </p>
                        </div>
                      )}
                      {offer.type === 1 && (
                        <div className="tel">
                          <div className="tel1">
                            <Phone style={{ marginRight: "10px", background: "#e9564b", color: "white", borderRadius: "20px", padding: "2px" }} />
                            <h3> Télèphone :</h3>
                          </div>
                          <p>&nbsp;{offer.tel}  </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="imageOffre">
                    <img src={`http://localhost:8000/uploads/${offer.image}`} padding-top={20} />
                  </div>
                </div>
              </a>
            ))
          )}
        </ul>
        
      {modal && (
        <div className="modal" >
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content" data-aos="slide-in">
            <h2>Voir Plus...</h2>
            <p offer={selectedOffer} resultatAPI={resultatAPI} />
            <div className="descriptionModell">
              <p> <h3>Description:</h3>{selectedOffer.description}</p>
            </div>
            <div className="descriptionModell">
              <p offer={selectedOffer}> <h3>Nom:</h3> {selectedOffer.nom}</p>
            </div>
            <div className="descriptionModell">
              <p> <h3 offer={selectedOffer}>Prix:</h3>
              <del style={{color:"red"}}>{selectedOffer.prix}</del> <span className="reduced-price">{(selectedOffer.prix/100)*(100-selectedOffer.reduction)}</span></p>
              {/* {selectedOffer.prix}</p> */}
            </div>
            <div className="descriptionModell">
              <p> <h3 offer={selectedOffer}>Adresse:</h3>{selectedOffer.adress}</p>
            </div>
            <div className="descriptionModell">
              <p> <h3 offer={selectedOffer}>Télèphone:</h3>{selectedOffer.tel}</p>
            </div>
            <button className="close-modal" onClick={toggleModal}>
              x
            </button>
          </div>
        </div>
      )}
    </div>
    </>
  );
}
