import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './newsletter.css'
import swal from 'sweetalert'


const Newsletter = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_w7ugdtx', 'template_h4zj8hu', form.current, 'aDokYIGmfN_zW4VVO')
      .then((result) => {
        console.log(result.text);
        swal("", "", "success");

    }, (error) => {
        console.log(error.text);
    });
    e.target.reset()
  };
  return (
    <div className= "Container" >
    
      <div className="Desc">Pour plus d'information.</div>
        < form ref={form} onSubmit={sendEmail} >
          <div className="InputContainer">
            <input className="Input" type="email" placeholder="Votre email" name="user_email" required/> 
          </div>
          <button className="Button" type="submit">
            Envoyer
          </button>
        </form>
      </div>
    
  );
};

export default Newsletter;