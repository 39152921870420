import React, { useState, useEffect } from 'react';
import "../components/Sante.css";
import conimg from "../components/assets/yooreed.jpg";
import ImageContact from "../components/ImageContact";
import { Details } from "../components/Details";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Footr from "../components/Footr";
import axios from 'axios';
import swal from 'sweetalert';

const Sante = () => {
  const [resultats, setResultats] = useState([]);  // Utilisez un seul état pour les résultats
  const [users, setUsers] = useState([]);
  const [selectedGouvernorat, setSelectedGouvernorat] = useState('');
  const [loading, setLoading] = useState(true); // État de chargement
  const [error, setError] = useState(false);    // État d'erreur

  // Chargement initial des gouvernorats
  useEffect(() => {
    fetchGouvernorats();
  }, []);

  // Fonction pour récupérer la liste des gouvernorats
  const fetchGouvernorats = () => {
    const token = localStorage.getItem('token');  // Vérifiez si le token est disponible
    if (!token) {
      swal('Erreur', 'Vous devez être connecté pour accéder à cette page', 'error');
      return;
    }

    axios
      .get('http://localhost:8000/api/adherent/gov', {
        headers: {
          Authorization: `Bearer ${token}`,  // Utilisation du token pour l'authentification
        },
      })
      .then((res) => {
        setUsers(res.data); // Stocke les gouvernorats dans l'état
        setLoading(false);  // Arrête l'affichage de "Loading" après la réponse
      })
      .catch((err) => {
        console.error(err);
        setLoading(false); // Arrête aussi le chargement en cas d'erreur
      });
  };

  // Fonction pour gérer la sélection d'un gouvernorat
  const handleGouvernoratChange = (event) => {
    const selectedGov = event.target.value;
    setSelectedGouvernorat(selectedGov);
    handleFilter(selectedGov);  // Filtre les partenaires dès qu'un gouvernorat est sélectionné
  };

  // Filtrage des partenaires en fonction du gouvernorat sélectionné
  const handleFilter = async (gov) => {
    try {
      const response = await axios.post(
        'http://localhost:8000/api/Guest/filter',
        {
          id: gov,  // Envoie l'ID du gouvernorat
          secteur_id: 1,  // Vous avez indiqué le secteur ID 1 (peut être dynamique si besoin)
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.length === 0) {
        setError(true);  // Affiche une erreur si aucun partenaire n'est trouvé
        swal('Aucun partenaire disponible', '', 'warning');
      } else {
        setError(false);  // Réinitialise l'erreur si des résultats sont trouvés
        setResultats(response.data);  // Stocke les résultats dans l'état
      }
    } catch (error) {
      console.error(error);
      setError(true);  // Affiche une erreur si la requête échoue
      swal('Erreur lors du filtrage des partenaires', '', 'error');
    }
  };

  return (
    <div className="mainSecteur">
      <Navbar />
      <div>
        <ImageContact
          cName="main1"
          image={conimg}
          overlay-img
          title="Les Partenaires"
        />
      </div>

      <div className="services">
        <div className="topbar">
          <h2>Gouvernorat</h2>
          <select
            value={selectedGouvernorat}
            id="language"
            onChange={handleGouvernoratChange}
            className="drop"
          >
            <option value="">Sélectionnez un gouvernorat</option>
            {users.map((item) => (
              <option key={item.id} value={item.id}>
                {item.nom}
              </option>
            ))}
          </select>
        </div>

        <div className="middle">
          <div className="description_title">
            <h1>Santé</h1>
          </div>

          <div className="details">
            {/* Affiche les résultats des partenaires */}
            {resultats.length > 0 ? (
              resultats.map((item, index) => (
                <Details
                  key={index}
                  title={item?.nom || 'Nom non disponible'}
                  description1={item?.profession?.nom || 'Profession non disponible'}
                  description={item?.specialite?.nom || 'Spécialité non disponible'}
                  lieu={item?.adress || 'Adresse non disponible'}
                />
              ))
            ) : (
              !loading && <p>Aucun résultat trouvé.</p>  // Affiche un message si aucun résultat
            )}

            {loading && <p>Loading...</p>}  {/* Affiche "Loading..." pendant le chargement */}
          </div>
        </div>
      </div>

      <Footr />
      <Footer />
    </div>
  );
};

export default Sante;
