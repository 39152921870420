
import Navbar from "../components/Navbar"
import ImageContact from "../components/ImageContact"
import conimg from "../components/assets/4.jpg";
import Service from "../components/Service";
import Footer from "../components/Footer"
import Footr from "../components/Footr"
function Services (){
    return(
        <> 
         <Navbar/>
         { <ImageContact               
               cName="main1"
               image={conimg} 
               overlay-img

               title="Nos Services"
              
              
             />}
             <Service/>
         <Footr/>
                <Footer/>
        </>

    )
}
export default Services;



