import React, { useState, useEffect } from 'react';
import "../components/Sante.css";
import conimg from "../components/assets/yooreed.jpg";
import ImageContact from "../components/ImageContact";
import { Details } from "../components/Details";
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Footr from "../components/Footr"
import axios from 'axios';
import swal from 'sweetalert';
const Education = () => {
  const [list, setList] = useState([])
  const [users, setUsers] = useState([]);
  const [selectedGouvernorat, setSelectedGouvernorat] = useState('');
  const [error, setError] = useState(false);
  const [resultats, setResultats] = useState(false);

  const yooreed = () => {
    axios
      .get('http://localhost:8000/api/adherent/gov')
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
const handleGouvernoratChange = (event) => {
  const selectedGov = event.target.value;
  console.log(event.target.value);
  setSelectedGouvernorat(selectedGov);
  handleFilter(selectedGov);
  console.log('Selected Gouvernorat:', selectedGov);
};

const handleFilter = async (gov) => {
  try {
    const response = await axios.post(
      'http://localhost:8000/api/Guest/filter',
      {
        id: gov, 
        secteur_id:7, 
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.data.length === 0) {
      setError(true);
      swal('Aucun partenaire disponible', '', 'warning');
      setResultats(null);
    } else {
      setError(false);
      setResultats(response.data);
      console.log(response.data);
    }
  } catch (error) {
    console.log(error);
    setError(true);
    swal('Erreur lors du filtrage des partenaires', '', 'error');
  }

 
};



  return (
    <div className="mainSecteur">
      <Navbar/>
    <div> { <ImageContact               
      cName="main1"
      image={conimg} 
      overlay-img
      title="Les Partenaires"/>}</div>
      <div className="services">
        <div className="topbar">
          <h2>Gouvernorat</h2>
          <select
            value={selectedGouvernorat}
            id="language"
            onChange={handleGouvernoratChange}
            className="drop"
            onClick={yooreed}

          >
            <option value=""> </option>
            {users.map((item) => (
              <option key={item.id} value={item.id}>
                {item.nom}
              </option>
            ))}
          </select>
        </div>
        <div className="middle">
          <div className="description_title">
            <h1>Loisir</h1>
          </div>
          <div className="details">
            {(resultats && resultats.length > 0) ? (
            resultats.map((item,index) => <Details
              key={index}
              title={item.nom}
             description1={item.profession.nom}
             description={item.specialite.nom}
              lieu={item.adress}
            />)
            ) : (
            list.map((item,index) => <Details
              key={index}
              title={item.nom}
              description1={item.profession.nom}
              description={item.specialite.nom}
              lieu={item.adress}
            />)
            )}
          </div>
        </div>
      </div>

      <Footr/>
                <Footer/>
    </div>
  );
};

export default Education;
