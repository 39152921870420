import React, { useState } from "react";
import "./Modal.css";
import { Link } from "react-router-dom";
import Popup from "./../../../routes/Popup";
import Controls from "./controls/Controls";
import Button from "./controls/Button";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import { toast } from "react-toastify";

export default function Modal() {
  const URL = "http://localhost:8000/api/partenaire/Scanner";
  const { token } = useAuth();
  console.log(token);
  const [modal, setModal] = useState(false);
  const [code, setCode] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("codeBar", code);

    const toastId = toast.loading("Chargement...");
    try {
      const { data } = await axios.post(URL, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      toast.update(toastId, {
        render: data,
        isLoading: false,
        autoClose: 7000,
        type: "success",
      });
    } catch (error) {
      if (`${error?.response?.status}`.startsWith("4")) {
        toast.update(toastId, {
          render: error?.response?.data?.message,
          isLoading: false,
          autoClose: 7000,
          type: "error",
        });
      } else {
        toast.update(toastId, { isLoading: false, autoClose: 1 });
      }
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      <Link onClick={toggleModal} className="btn-modal">
        Scanne
      </Link>

      <Popup title="Scan Code" openPopup={modal} setOpenPopup={setModal}>
        <div>
          <form onSubmit={handleSubmit}>
            <Controls.Input
              name="codeBar"
              label="Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <br />
            <Controls.Button type="submit" size="large" text="Entrer" />
          </form>
        </div>

        {/* <button className="close-modal" onClick={toggleModal}>
          x
        </button> */}
      </Popup>
    </>
  );
}
