import Navbar from "../components/Navbar"
import Log from "../components/Log";

import React from 'react'

function Login() {
  return (
   <>
     <Navbar/>
                <Log/>
                
   </>
  )
}

export default Login