
import"./YooreedContact.css"
const YooreedContact = ()=> {
    return (
        <div className="yooreedData2">
          
                        <h1>05</h1>
                        <h3> CONTACTEZ-NOUS </h3>
                       <div className="service2">
                         <div className="service-text">
                           <h2>"Yooreed" est à votre service </h2> 
                           
                            </div>
                      
                      </div>
                      
            </div>
     
    );
};
export default YooreedContact;
