import React from 'react'
import"./YooreedChart.css"
function YooreedChart() {
  
  return (
    <div class="counter">
                <div class="numbers">
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
                <div>5</div>
                <div>6</div>
              
                </div>
              </div>
  )
}

export default YooreedChart