import NavB from "../components/adherent/navNew"
import New from "../components/adherent/New"
import { useState } from "react";
import { useAuth } from "../AuthContext";
function News (){
    
    const [resultts, setResultts] = useState([]);
    const { isSignedIn } = useAuth();
    if (!isSignedIn) return <h1>Not Logged In</h1>;
    return(
        <>
            <NavB setResults={setResultts}/>
            <New  results={resultts}/>

        </>


    );
}
export default News;